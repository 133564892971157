import { Chart } from '../chart.model';
import { TableChartOptions } from './table-options';
import { Tree } from './tree.model';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export interface TransformedHTMLDataInterface {
    header: Array<any>;
    body: Array<any>;
}

const mapSettingsKey: Map<string, string> = new Map();
mapSettingsKey.set('decisionRules', 'decisionRules');
mapSettingsKey.set('scale', 'yAxis');
mapSettingsKey.set('plotBands', 'yAxis');
mapSettingsKey.set('show_default_language', 'show_default_language');
mapSettingsKey.set('show_attributes_blocks', 'show_attributes_blocks');
mapSettingsKey.set('show_grey_zone', 'show_grey_zone');

export class Table extends Chart {
    protected _baseParameters: any = {};
    public onOptionChangeSubject = new Subject<any>();
    public static mapSettings: Map<string, string> = mapSettingsKey;
    isPSR: boolean = false;
    tableLength: number;

    constructor(
        _parameters?: any,
        _data?: any,
        _lang?: string,
        _filters?: any,
        private _translateService?: TranslateService
    ) {
        super(_parameters, _data, _lang, _filters);
        this.isPSR = _data.isPSR;
        this.tableLength = this.data.length;
        this.generateParametersAssignment(this.parameters, this.lang);
        this.generateDataAssignment(this.data, this.lang);
        (<TransformedHTMLDataInterface>this.parameters.transformedHTMLData) = this.parameters.tree.createTable();
        this.tableRawData = this.parameters.transformedHTMLData;
    };

    private generateParametersAssignment(parameters: any, lang: string) {
        // Handling title & subtitle translation
        try { this.updateChartTranslations(parameters, lang); }
        catch( e ) { /* No params to translate */ };
        Object.assign({ ...this._baseParameters, parameters });
        Object.keys({ ...this._baseParameters }).forEach((k) => {
            this.parameters[k] = Object.assign({ ...this._baseParameters[k] }, parameters[k])
        });
        Object.keys({ ...parameters }).forEach((k) => {
            this.parameters[k] = Object.assign({ ...this.parameters[k] }, parameters[k])
        });
    };

    private applyColor(routineObject: any) {
        const colorScale = [...Chart.colorScale];
        const benchColor = colorScale.shift();
        let benchFound: boolean = false;
        let colorIndex = 0;
        routineObject.routines.forEach(r => {
            if (!benchFound && r.isBench) {
                benchFound = true;
                r.color = benchColor;
            } else {
                r.color = colorScale[colorIndex++];
            }
        });
    }

    protected generateDataAssignment = (data: any, lang: string) => {
        data.find(el => el.attribute.label == 'RISK').values.map((el1 => el1.isPSR = this.isPSR)) ;
        this.isPSR ? data.find(el => el.attribute.label == 'RISK').values[0].tableLength = this.tableLength : '';
        this._data = data;

        if(this.routines.routines) {
            this.applyColor(this.routines);
        }

        const method = this.parameters.compute ? this.parameters.compute.method : null;
        const method_parameters = this.parameters.compute ? this.parameters.compute.parameters : null;
        const payload = this.formatData(data, method, method_parameters, lang);
        this.parameters.series = payload;
        this.parameters.tree = new Tree(this.parameters.series);
    };

    /**
     * buildGraphOptions
    */

    public buildGraphOptions = (options: any) => {
        const { title, subtitle, plotOptions, transformedHTMLData, tree, ...rest } = options;
        this.options = new TableChartOptions({
            general: { title, subtitle },
            plotOptions,
            columns: tree.header[0]
        }, this.onOptionsChange);
    };

    private getValuesFromKey = (object: any) => {
        object = Object.values(object);
        object.forEach(element => {
            if (!Object.values(element.children).length) {
                delete element.children;
                return;
            }
            else element.children = this.getValuesFromKey(element.children)
        });
        return object;
    };

    private _formatDefault = (data: any, _lang: string): any => {
        // CREATE MATRIX TABLE
        const tempHeader = [];
        const tempBody = [];
        /**
         * FUNCTION TO CREATE MATRIX WITH ID ON HEADERS
         * @param item DATA TO PROCESS
         * @param tempHeader ARRAY FOR HEADERS
         * @param tempBody ARRAY FOR BODY
         * @param headerLevel SHOULD BE 0, KEEP TRACK OF HEADERS DEPTH
         */

        const addItem = (item, tempHeader, tempBody, headerLevel = 0, fullHeader = null, currentHeader = null, lang: string) => {
            // SAVE THE FULLHEADER
            if (!fullHeader) fullHeader = tempHeader;
            // RETRIEVE THE INDEX FOR HEADERS ID
            item.forEach((item, index) => {
                // CHECK IF PROPERTY ATTRIBUTE EXIST
                if (item.attribute) {
                    const { label, ...other } = item.attribute;
                    // VARIABLE TO KEEP TRACK OF THE HEADERS
                    let newHeaderTrack;
                    if (!currentHeader) {
                        // CREATE NEW TRACK FOR EACH MASTER HEADER
                        newHeaderTrack = {
                            headerIndex: index,
                            headerLabel: this.getObjectValueTranslation(label, lang),
                            filled: [{
                                label: this.getObjectValueTranslation(label, lang),
                                filled: false
                            }]
                        };
                    } else {
                        // NEED A VARIABLE IF HEADER DEPTH LEVEL IS NOT = TO MAX DEPTH
                        currentHeader.filled.push({
                            label: this.getObjectValueTranslation(label, lang),
                            filled: false
                        });
                        // IF CURRENT HEADER DEPTH > THAN PREVIOUS HEADER DEPTH : FILL PREVIOUS HEADER
                        if (fullHeader.length < headerLevel + 1 && currentHeader.headerIndex > 0) {
                            const referenceHeader = fullHeader[fullHeader.length - 1];
                            for (let col = 0; col < fullHeader[0].length - 1; col++) {
                                const lastHeader = referenceHeader[col];
                                if (!tempHeader[headerLevel]) tempHeader[headerLevel] = [];
                                tempHeader[headerLevel].push(lastHeader);
                            }
                        }
                    }
                    // CREATE HEADER LEVEL IF NONEXISTANT
                    if (!tempHeader[headerLevel]) tempHeader[headerLevel] = [];
                    // CREATE HEADER OBJECT
                    const newHeaderCell = {
                        id: this.getObjectValueTranslation(label, lang) + '-' + headerLevel + '-' + index,
                        label: this.getObjectValueTranslation(label, lang),
                        other: other
                    };
                    // ADD INTO HEADER ARRAY
                    tempHeader[headerLevel].push(newHeaderCell);
                    // CHECK CURRENT HEADER LEVEL
                    if (headerLevel > 0) {
                        // ADD PARENT HEADERS
                        for (let level = headerLevel - 1; level >= 0; level--) {
                            // CHECK PARENTS LENGTH
                            if (tempHeader[level].length < tempHeader[headerLevel].length) {
                                tempHeader[level].push(tempHeader[level][tempHeader[level].length - 1]);
                            }
                        }
                    }
                    addItem(item.values, tempHeader, tempBody, headerLevel + 1, fullHeader, newHeaderTrack || currentHeader, lang);
                } else {
                    if (!tempBody[index]) tempBody[index] = [];
                    const cellToPush = item.label && typeof item.label === 'string' ? item : {...item, singleValue: true}
                    tempBody[index].push(cellToPush);
                    let currentFilled = currentHeader.filled[currentHeader.filled.length - 1];
                    // IF CURRENT HEADER DEPTH < PREVIOUS HEADER DEPTH : FILL CURRENT HEADER IF NOT ALREADY DONE
                    if (fullHeader.length > headerLevel && !currentFilled.filled) {
                        const lastLine = tempHeader[headerLevel - 1];
                        const lastHeader = lastLine[lastLine.length - 1];
                        for (let line = headerLevel; line < fullHeader.length; line++) {
                            tempHeader[line].push(lastHeader);
                        }
                        currentFilled.filled = true;
                    }
                }
            });
        }
        // CREATE MATRIX
        addItem(data, tempHeader, tempBody, 0, null, null, _lang);
        // TEMP VARIABLE TO STORE TREE DATA
        const temp = {};
        // GET LINE
        tempBody.forEach((line, lineIndex) => {
            // TEMP VARIABLE VOR RECURSION
            let tempo = null
            // GET COL
            line.forEach((col, colIndex) => {
                // CREATE VARIABLE FOR HEADERS
                const parentHeader = {};
                // TEMP VARIABLE FOR NESTED HEADER
                let tempoHeader = null;
                // CHECK IF PARENT HEADERS NEEDED
                if (tempHeader.length > 1) {
                    // BEGIN FROM BOTTOM
                    for (let headerLevel = 0; headerLevel < tempHeader.length; headerLevel++) {
                        // CREATE VARIABLE TO STORE CURRENT HEADER
                        let currentHeader;
                        // CHECK IF TEMP AVAILABLE
                        if (tempoHeader) {
                            currentHeader = tempoHeader;
                        } else {
                            currentHeader = parentHeader
                        }
                        // ADD LABEL INTO CURRENT HEADER
                        currentHeader.label = tempHeader[headerLevel][colIndex];
                        // CHECK IF HAVE PARENT
                        if (headerLevel !== tempHeader.length - 1) {
                            // ADD A PARENT
                            currentHeader.children = {};
                            // CHANGE TEMPHEADER
                            tempoHeader = currentHeader.children;
                        }
                    }
                } else {
                    // CREATE PARENT SINGLE PARENTLABEL IF NO PARENT
                    parentHeader["label"] = tempHeader[0][colIndex];
                }
                // CREATE VARIABLE FOR CURRENT COL VALUE
                const tempCol = col.hasOwnProperty("singleValue") ? col.value : col;
                const name = (tempCol === null || (typeof tempCol === "object" && !tempCol.hasOwnProperty('english'))) ? ("generated-" + lineIndex + "-" + colIndex) : this.getObjectValueTranslation(tempCol, _lang);
                if (!tempo) tempo = temp;
                const current = tempo[name] = tempo[name] || {};
                // FILL CURRENT
                current.value = this.getObjectValueTranslation(tempCol, _lang);
                current.translations = tempCol;
                current.label = tempHeader[tempHeader.length - 1][colIndex].label;
                current.id = tempHeader[tempHeader.length - 1][colIndex].id;
                current.parentLabel = parentHeader;
                current.children = current.children || {};
                current.props = col.hasOwnProperty("singleValue") ? {...col} : {...tempCol.value};
                // CHANGE TEMPO FOR RECURSION
                tempo = current.children;
            });
        });
        // RETRIEVE ONLY VALUES
        const payload = this.getValuesFromKey(temp);
        return payload;
    };

    private buildNewData = (object: any, thing: Array<any>) => {
        const [first, ...next] = thing;
        object[first.label] = (object[first.label] || {
            label: first.label,
            value: first.value,
            children: {}
        });
        if (next.length) this.buildNewData(object[first.label].children, next);
        else return;
    };

    private buildTreeRepartition = (object: any, data: Array<any>, parameters: any) => {
        const pCopy = [...parameters];
        if (data && !data[0].hasOwnProperty('values')) {
            const payload = pCopy.map((param: any) => {
                param.value = data.filter((x: any) => {
                    if (x.value == param.rule) return x;
                }).length;
                return param;
            });
            this.buildNewData(object, payload);
        } else {
            data.forEach((item: any) => {
                const currentLabel = item.attribute.label;
                const currentValue = item.attribute.value;
                const currentObject = object[currentValue] = object[currentValue] || {
                    label: currentLabel,
                    value: currentValue,
                    children: {}
                };
                this.buildTreeRepartition(currentObject.children, item.values, parameters);
            })
        }
    };


    protected formatData = (data: Array<any>, method: string, parameters: any, lang: string): any => {
        return this._formatDefault(data, lang);
    };

    private onOptionsChange = (options: any) => {
        for (let o in options) {
            if (o === 'general') {
                Object.assign(this.parameters, options[o]);
            }
            else if (o === 'columns') {
                this.displayHideFormatedHTMLData(this.parameters.transformedHTMLData, options[o]);
            }
            else {
                for (let e in options[o]) {
                    if (Array.isArray(options[o][e])) {
                        options[o][e].forEach(x => {
                            Object.assign(this.parameters[e].find(y => y.name === x.name), x)
                        })
                    } else {
                        if (this.parameters.hasOwnProperty(e)) Object.assign(this.parameters[e], options[o][e]);
                    }
                }
            }
        };

        const { ...parameters } = this.parameters;
        Object.assign(this.parameters, { ...parameters });
        this.onOptionChangeSubject.next(this.parameters);
        this.updateChartTranslations(this.parameters, this.lang);
    };

    private displayHideFormatedHTMLData = (baseTable: any, options: Array<any>): void => {
        for (let option of options) {
            //Handling body 
            baseTable.tbody.forEach((lines) => {
                lines.forEach((cel) => {
                    if ([cel.value.parentLabel.label.id, cel.value.parentLabel.label.label].join('-') == option.id) cel.hidden = option.hidden;
                });
            });

            //Handling header 
            baseTable.thead.forEach((lines) => {
                lines.forEach((col) => {
                    if (col.id.search(option.id) !== -1) col.hidden = option.hidden;
                });
            });
        }
    };

    //protected setParamsToAxis: (params: any, axis: any) => void;

    protected median = (array: Array<any>) => {
        const mid = Math.floor(array.length / 2),
            nums = [...array].sort((a, b) => a.value - b.value);
        return array.length % 2 !== 0 ? nums[mid].value : (nums[mid - 1].value + nums[mid].value) / 2;
    };

    /**
     * updateChartTranslations
     * Method to manage languages changes
     * @param parameters : any : Chart's parameters
     * @param lang : string
    */
    private updateChartTranslations = (parameters: any, lang: string): void => {
        this.updateTableTitleTranslations(lang, parameters);
        this.updateTableSubTitleTranslations(lang, parameters);
    };

    private updateTableTitleTranslations = (lang: string, parameters: any) => {
        parameters.title.text = this.getObjectValueTranslation(parameters.title.translations, lang);
    };

    private updateTableSubTitleTranslations = (lang: string, parameters: any) => {
        parameters.subtitle.text = this.getObjectValueTranslation(parameters.subtitle.translations, lang);
    };

    protected formatRawData = (data: Array<any>, lang: string) => {};
};