import {
  Injectable
} from '@angular/core';
import {
  HttpClient, HttpParams
} from '@angular/common/http';

import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
import {
  Observable, of
} from 'rxjs';

import {
  Actiview,
  ArcsPanelist,
  ArcsParticipation,
  DNACollection,
  DNAComponent,
  Feedback,
  HubById,
  ImageBlob,
  User,
  UserWorkspace,
  Preferences,
  Resp,
  Classifications,
  GroupVolunteers,
  ArcsParticipationList
} from '../../types';
import {
  environment
} from '../../../environments/environment';
import {
  WorkspaceService
} from '../../general/my-profile/workspaces/workspaces.service';
import * as uuid from "uuid";
import { map } from 'rxjs/operators';
@Injectable()
export class HttpRestService {

  envName: string;
  serverUrlStudio: string;

  constructor(
    private http: HttpClient,
    private workspaceService: WorkspaceService
  ) {
    this.envName = environment.namehttp();
    this.serverUrlStudio = environment.server_url_studio();
  }

  exportVisualizationToExcel(campaignId: string, objectType: string): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      this.http.get(this.serverUrlStudio + '/exportExcel/' + objectType + '/' + campaignId, {
        responseType: "arraybuffer",
        observe: 'response'
      }).subscribe((response: any) => {
        var type = null;
        var blob = new Blob([response.body], {
          type: type
        });
        var timestamp = new Date().getTime();
        FileSaver.saveAs(blob, 'export' + timestamp + '.xlsx');
        resolve();
      }, () => reject());
    });
  }

  forgotpwd(user: any): Observable<any> {
    user.provider = 'local';
    user.pzone = new Date().getTimezoneOffset();
    return this.http.post(this.serverUrlStudio + '/forgotpwd', user);
  }

  isUniqueName(name: string, type: string): Observable<boolean> {
    return this.http.get<{result: boolean}>(`${environment.apiSharedUrl}/v1/isUniqueName/${type}`, {
      params: new HttpParams().set('name', name)
    }).pipe(map(result => result.result));
  }

  register(user: any): Observable<any> {
    user.provider = 'local';
    user.pzone = new Date().getTimezoneOffset();
    return this.http.post(this.serverUrlStudio + '/register', user);
  }


  /********** AUTH **********/

  openIDInformations(info: string): Observable<any> {
    return this.http.get<any>(this.serverUrlStudio + '/auth/waad/' + info + '/' + new Date().getTimezoneOffset());
  }

  login(user: any): Observable<User> {
    user.provider = 'local';
    user.pzone = new Date().getTimezoneOffset();
    return this.http.post<User>(this.serverUrlStudio + '/auth/login', user);
  }

  loginV2(currentLanguage: string): Observable<any> {
    return this.http.get<any>(`${environment.apiCoreUrl}/v1/accounts/.me/?currentLanguage=${currentLanguage}`);
  }

  logout(): Observable<string> {
    return this.http.get<string>(this.serverUrlStudio + '/auth/logout');
  }

  /**********  ACTIVIEW **********/

  getActiview(idActiview: string, refresh: boolean = false): Observable<Actiview> {
    return this.http.get<Actiview>(`${environment.apiApptalkUrl}/v1/actiview/${idActiview.trim()}?refresh=${refresh}`);
  }

  getActiviewBySynergy(idSynergy: string, refresh: boolean = false): Observable<Actiview> {
    return this.http.get<Actiview>(`${environment.apiApptalkUrl}/v1/actiview/synergy/${idSynergy.trim()}?refresh=${refresh}`);
  }

  /**********  ARCS **********/

  getArcsInformation(hub: HubById, studyNumber: string): Observable<ArcsParticipationList> {
    return this.http.get<ArcsParticipationList>(`${environment.apiApptalkUrl}/v1/arcs/participationdata/${hub}/${studyNumber.replace(/\//g, "~")}`)
  }

  getPanelistInformation(hub: HubById, panelistNumber: string): Observable<ArcsPanelist> {
    return this.http.get<ArcsPanelist>(`${environment.apiApptalkUrl}/v1/arcs/panelistdata/${hub}/${panelistNumber}`)
  }

  patchPanelistInformation(hub: HubById, panelistNumber: string, classifications: Classifications[]) {
    return this.http.patch(`${environment.apiApptalkUrl}/v1/arcs/panelistdata/${hub}/${panelistNumber}`, classifications);
  }

  /**********  HORS ARCS **********/

  associateGroupPanelistHorsArcs(hub: HubById, nameGroupPanelist: string, studyId: string, areVolunteersAdded: boolean = true): Observable<any> {
    return this.http.put<any>(`${environment.apiApptalkUrl}/v1/panelistGroupsHorsArcs/${hub}/${nameGroupPanelist.replace(/\//g, "~")}/campaigns/${studyId}`, areVolunteersAdded)
  }

  getGroupPanelistHorsArcs(hub: HubById, nameGroupPanelist: string): Observable<GroupVolunteers> {
    return this.http.get<GroupVolunteers>(`${environment.apiApptalkUrl}/v1/panelistGroupsHorsArcs/${hub}/${nameGroupPanelist.replace(/\//g, "~")}`);
  }

  getPanelistHorsArcsInformation(hub: HubById, panelistNumber: string): Observable<ArcsPanelist> {
    return this.http.get<ArcsPanelist>(`${environment.apiApptalkUrl}/v1/horsarcs/panelistdata/${hub}/${panelistNumber.replace(/\//g, "~")}`);
  }

  /********** DATALAKE **********/
  sendDatalakeData(id: string, collection: DNACollection) {
    return this.http.get(`${this.serverUrlStudio}/datalake/${collection}/${id}`);
  }

  /********** FEEDBACK **********/

  createFeedBack(feedback: Feedback) {
    return this.http.post<string[]>(`${environment.apiSharedUrl}/v1/feedbacks`, feedback);
  }

  deleteFeedBack(id: string): Observable<string[]> {
    return this.http.delete<string[]>(`${environment.apiSharedUrl}/v1/feedbacks/${id}`);
  }

  getFeedBacks(): Observable<Resp> {
    return this.http.get<Resp>(`${environment.apiSharedUrl}/v1/feedbacks`);
  }

  getFeedBackById(id: string): Observable<Feedback> {
    id = '17ef1a94-194f-4ee2-b99b-6db0ace367e3';
    return this.http.get<Feedback>(`${environment.apiSharedUrl}/v1/feedbacks/id/${id}`);
  }

  /********** LANGUAGE **********/

  getLanguages(): Observable<{ list: string[] }> {
    return this.http.get<{ list: string[] }>(environment.apiSharedUrl + '/v1/languages');
  }

  setFavoriteLanguage(language: string, peopleKey: string): Observable<string> {
    return this.http.patch<any>(`${environment.apiSharedUrl}/v1/accounts/settings/${peopleKey}`, {
      'currentLanguage': language
    });
  }

  getUserPreferences(user: User): Observable<Preferences[]> {
    return this.http.get<Preferences[]>(this.serverUrlStudio + '/users/' + user.track + '/preferences');
  }

  /** DNA-2 */

  getUserLanguage(peopleKey: string): Observable<{ currentLanguage: string }> {
    return this.http.get<{ currentLanguage: string }>(environment.apiSharedUrl + '/v1/accounts/settings/' + peopleKey + '/language');
  }

  getUserCurrentWorkspace(peopleKey: string): Observable<any> {
    return this.http.get<any>(environment.apiSharedUrl + '/v1/accounts/settings/' + peopleKey + '/workspace');
  }

  getUserWorkspaces(peopleKey: string): Observable<any> {
    return this.http.get<any>(environment.apiSharedUrl + '/v1/accounts/settings/' + peopleKey + '/workspaces');
  }

  getMainTranslations(): Observable<any> {
    return this.http.get<any>(environment.apiSharedUrl + '/v1/translations?filters=[language[in]=("english","french")]');
  }

  getBuiltUser(peopleKey: string): Observable<User> {
    return this.http.get<User>(environment.apiSharedUrl + '/v0/accounts/settings/' + peopleKey + '/tokenv1/digitexpert');
  }

  executeTableRequest(params): Observable<Resp> {
    return this.http.get<Resp>(`${environment.apiSharedUrl}/${params}`);
  }

  /********** MEDIA *****  *****/

  uploadLargeDataRecord(evaluationId: string, largeData: string) {
    return this.http.post(environment.apiStudiesUrl + '/evaluations/' + evaluationId + '/largedatarecords', {
      largeData: largeData,
    });
  }

  downloadMedia(mediaUUID: string): Observable<ImageBlob> {
    return this.http.get<ImageBlob>(`${environment.apiStudiesUrl}/v1/medialoader/${mediaUUID}`);
  }

  uploadMedia(media: ImageBlob, uuid: string): Observable<{ mediaUUID: string }> {
    return this.http.post<any>(`${environment.apiStudiesUrl}/v1/medialoader/${uuid}`, media);
  }

  downloadPowerBICaptureByURL(mediaUUID: string, studyId: string): Observable<ImageBlob> {
	return this.http.get<ImageBlob>(`${environment.apiStudiesUrl}/v1/campaigns/${studyId}/powerbicaptures/${mediaUUID}`);
  }

  getPowerBICaptureURL(idCampaign: string, uuid: string): string {
	return `${environment.apiStudiesUrl}/v1/campaigns/${idCampaign}/powerbicaptures/${uuid}`;
  }

  downloadLargeDataRecord(mediaUUID: string, idEvaluation: string ): Observable<ImageBlob> {
	return this.http.get<ImageBlob>(`${environment.apiStudiesUrl}/v1/evaluations/${idEvaluation}/largedatarecords/${mediaUUID}`);
  }

  uploadPowerBICapture(idCampaign: string, capture: { media: string, type: string }, uuid: string): Observable<{ id: string, dataUUID: string }> {
    return this.http.post<{ id: string, dataUUID: string }>(`${environment.apiStudiesUrl}/campaigns/${idCampaign}/powerbicaptures/${uuid}`, capture);
  }

  /********** USER **********/

  addOrRemoveFavorite(user: User, id: string, typeState: string): Observable<string> {
    return this.http.patch<any>(`${environment.apiSharedUrl}/v1/accounts/settings/${user.track}`, {
      favorites: {
        type: typeState,
        id: id
      }
    });
  }

  addOrRemoveTest(user: User, id: string, typeState: string): Observable<string> {
    return this.http.put<string>(this.serverUrlStudio + '/users/tests/' + user.track, {
      type: typeState,
      id: id
    });
  }

  getPeople(id: string): Observable<User> {
    var query = "";
    if (id) {
      query = '?people=' + id;
    }
    return this.http.get<User>(this.serverUrlStudio + '/users' + query);
  }

  searchUsersForSchedule(): Observable<any[]> {
    return this.http.get<any[]>(this.serverUrlStudio + '/users/schedule');
  }

  /********** WORKSPACE **********/

  setFavoriteWorkspace(workspace: UserWorkspace, peopleKey: string): Observable<string> {
    console.log("workspace.id:",workspace.id)
    return this.http.patch<any>(`${environment.apiSharedUrl}/v1/accounts/settings/${peopleKey}`, {
      currentWorkspace: workspace.id
    });
  }

  /************* PHOTOS **********/
  downloadMediaPhotos(mediaUUID: string): Observable<ImageBlob> {
    return this.http.get<ImageBlob>(`${environment.apiStudiesUrl}/v1/medialoader/${mediaUUID}`);
  }

  uploadMediaPhotos(media: ImageBlob, uuid: string): Observable<{ mediaUUID: string }> {
    return this.http.post<any>(`${environment.apiStudiesUrl}/v1/medialoaderphotos/${uuid}`, media);
  }

  /************* FRAME **********/
  getFramePersonalData(activityNumber): Observable<any> {
    return this.http.get<any>(`${environment.apiApptalkUrl}/v1/frame/personal_data/${activityNumber}`);
  }

  /************* RESEARCH AXES **********/
  getResearchAxesData(): Observable<any> {
    return this.http.get<any>(`${environment.apiApptalkUrl}/v1/actiview/ref/research-axes`);
  }

}
