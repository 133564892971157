import { OnePagerService } from '@app/shared/services/one-pager.service';

import {of as observableOf,  Observable } from 'rxjs';

import {catchError, take, tap} from 'rxjs/operators';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { OnePager, User, CampaignOnePager, UserInCampaign, CampaignStates } from '@app/types';
import { CampaignService } from '../../../../campaigns.service';
import { ErrorManagerService } from '@app/shared/services/errorManager.service';
import { UtilService } from '@app/shared/services/util.service';
import { ApplicationInsightsService } from '@app/shared/services/applicationInsights.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'dna-one-pager-head',
  templateUrl: './one-pager-head.component.html',
  styleUrls: ['./one-pager-head.component.less']
})
export class OnePagerHeadComponent implements OnInit, AfterViewInit {

  onePager: OnePager;
  initialOnePager: OnePager;
  campaign: CampaignOnePager;
  headerForm: FormGroup;
  initialHeaderForm: FormGroup;
  booleanIsOnePagerFromCampaign: boolean;
  users: User[];
  isChanged = false;
  submitted = false;
  formValidAndPublished = true;
  initTime = performance.now();

  constructor(
    private onePagerService: OnePagerService,
    private campaignService: CampaignService,
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private errorManagerService: ErrorManagerService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.init();
    this.initialHeaderForm = _.cloneDeep(this.headerForm);
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign One Pager Head', '', performance.now() - this.initTime, templateUrl);
  }

  private assignFormInOnePager = (form: any) => {
    this.onePager.identification = this.onePager.identification ? Object.assign(this.onePager.identification, form) : form;
    this.onePagerService.setOnePager(this.onePager);
  }

  catchError = (err) => {
    this.errorManagerService.catchError(err);
    return observableOf(this.onePager);
  }

  init() {
    this.campaign = this.onePagerService.getCampaign();
    this.onePager = this.onePagerService.getOnePager();
    this.initialOnePager = _.cloneDeep(this.onePager);
    this.users = this.campaignService.users;
    this.headerForm = this.initHeaderForm();
    this.onChange();
  }

  private initHeaderForm(): FormGroup {
    return new FormGroup({
      actiview: new FormControl({value: _.get(this.onePager, 'identification.actiview', ''), disabled: true}),
      bridge: new FormControl({value: _.get(this.onePager, 'identification.bridge', ''), disabled: true}),
      expert: new FormControl(this.formatUsersName(_.get(this.onePager, 'identification.expert', []))),
      hub: new FormControl(_.get(this.onePager, 'identification.hub', '')),
      fieldwork: new FormControl(_.get(this.onePager, 'identification.fieldwork', '')),
      laboratory: new FormControl(_.get(this.onePager, 'identification.laboratory', '')),
      manager: new FormControl(_.get(this.onePager, 'identification.manager', [])),
      orchestra: new FormControl({value: _.get(this.onePager, 'identification.orchestra'), disabled: true}),
      projectName: new FormControl(_.get(this.onePager, 'identification.projectName', '')),
      studyDate: new FormControl(_.get(this.onePager, 'identification.studyDate', '')),
      estimatedContribution: new FormControl(_.get(this.onePager, 'identification.estimatedContribution', ''), Validators.required)
    });
  }

  onCancel() {
    this.headerForm = _.cloneDeep(this.initialHeaderForm);
    this.onChange();
  }

  onChange() {
    this.isChanged = false;
    this.headerForm.valueChanges.pipe(
      tap(() => this.isChanged = true))
      .subscribe();
  }

  reinitForm() {
    this.onePagerService.buildOnePagerHead(this.onePager.identification, this.campaign);
    this.headerForm = this.initHeaderForm();
    if (!_.isEqual(this.initialHeaderForm.value, this.headerForm.value)) { this.isChanged = true; }
  }

  save(headerForm: FormGroup) {
    this.submitted = true;
    if (!headerForm.invalid) {
      this.assignFormInOnePager(headerForm.value);
      this.updateOnePager()
      .subscribe((onePager: OnePager) => {
        this.onePager = onePager;
        this.initialOnePager = _.cloneDeep(onePager);
        this.onePagerService.setOnePager(this.onePager);
        this.initialHeaderForm = _.cloneDeep(this.headerForm);
      });
      this.onChange();
    } else {
      this.errorManagerService.displayMessage('ON_ERROR_FORM', 'danger');
    }
  }

  private updateOnePager = (): Observable<OnePager> => {
   this.errorManagerService.displayMessage('ON_SAVE_PROCESSING', 'info');
    return this.onePagerService.updateOnePager(this.onePager.id, this.onePagerService.getElementToUpdate(this.initialOnePager, this.onePager), _.get(this.campaign, 'id', '')).pipe(take(1),
      tap(() => this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE')),
      catchError(this.catchError), );
  }

  canDeactivate(): Observable<boolean> | boolean {
    this.formValidAndPublished = (this.headerForm.valid && this.campaign.state === CampaignStates.Published) || this.campaign.state !== CampaignStates.Published;
    if (!this.formValidAndPublished) {
      this.errorManagerService.displayMessage('ON_ERROR_FORM', 'danger');
    }
    return (this.utilService.canDeactivate(this.headerForm.value, this.initialHeaderForm.value) && this.formValidAndPublished);
  }

  formatUsersName(experts: UserInCampaign[]) {
    experts.forEach(e => {
      if (!e.name) {
        const user = this.users.find(u => u.key === e.key);
        e.name = user ? user.name : '';
      }
    });
    return experts.filter(expert => expert.name);
  }

}
