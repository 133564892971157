import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ApplicationInsightsService } from './applicationInsights.service';

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  handleError(error) {
    const applicationInsightsService = this.injector.get(ApplicationInsightsService);
    applicationInsightsService.trackException(error);
  }
}
