<div class="dna-chart-table" >
  <div class="dna-chart-table--captions">
    <div> {{ this.table.parameters?.title?.text || ('VIZUALIZATIONS.CHART_TITLE' | translate) }} </div>
    <div class="dna-table-chart-subtitle" *ngIf="this.table?.parameters?.subtitle?.text">
      {{ this.table.parameters?.subtitle?.text || ('VIZUALIZATIONS.CHART_SUBTITLE' | translate) }}
    </div>
      <dna-chart-sm-table
        *ngIf="this.dr && this.dr.length"
        [dataTable]="this.dr">
      </dna-chart-sm-table>
  </div>
  <div class="dna-chart-table-container">
    <table #chart>
      <thead *ngIf="this.table?.parameters?.transformedHTMLData?.thead?.length">
        <tr *ngFor="let headRow of this.table.parameters.transformedHTMLData.thead; trackBy: getHeaderLineId">
          <th
            [ngStyle]="{
              'min-width.px' : this.cellConfigs.index == index ? this.cellConfigs.width : false,
              'max-width.px' : this.cellConfigs.index == index ? this.cellConfigs.width : false,
              'text-align': 'center'
            }"
            [class.dna-table--cell-hidden]="head.hidden"
            [attr.colspan]="head.colspan"
            [attr.rowspan]="head.rowspan"
            *ngFor="let head of headRow;trackBy: getHeaderId; let index = index"
            (click)="this.getCallbackOrder(head.order == null ? null : head.headerIndex)">
            <span class="ui-column-resizer" (mousedown)="onMouseDown($event)"></span>
            {{ head.label | translate }}
            <span *ngIf="head.order != null" class="order-button" [ngClass]="this.getClass(head.order)">
              <span class="order-symb order-symb1"></span>
              <span class="order-symb order-symb2"></span>
            </span>
          </th>
        </tr>
      </thead>

      <tbody *ngIf="this.table?.parameters?.transformedHTMLData?.tbody[0]?.length">
        <tr *ngFor="let element of this.table.parameters.transformedHTMLData.tbody; trackBy: getLineId">
          <td *ngFor="let node of element; trackBy: getColId"
              [class.dna-table--centered-value]="(node.value?.value || node.value?.translations?.value) | isExistingSymbol : this.dr"
              [class.dna-table--master-td]="node.rowspan > 1 && node.value.label !== 'RISK'"
              [class.dna-td-graphic]="node.value.value && node.value.value.hasOwnProperty('custom') "
              [class.dna-table--cell-hidden]="node.hidden"
              [ngClass]="node.class"
              [attr.rowspan]="node.rowspan"
              [attr.data-flip]="node.id"
              [style.color]="node.color || this.coloredCell((node.value?.value || node.value?.translations?.value), this.dr)"
              [style.backgroundColor]="node.backgroundColor || this.coloredCell((node.value?.value || node.value?.translations?.value), this.dr)">

            <div *ngIf="node.value?.value?.custom">
              <dna-table-graph [data]="node.value.value" [table-instance]="this.table" class="graph-container"></dna-table-graph>
            </div>

            <ng-container *ngIf="!node.value?.value?.custom">
              <span [innerHTML]="getDisplayContent(node) | applyMultiTranslations: [this.table.lang, this.table?.parameters?.plotOptions?.default_language?.enabled ? Languages.Default : null] : Languages.Default"></span>
            </ng-container>
          </td>
        </tr>
      </tbody>

    </table>
  </div>
</div>
<div class="dna-table-chart--no-data" *ngIf="!this.table.parameters?.series?.length"> <span
    translate>VIZUALIZATIONS.NO_DATA_TO_DISPLAY</span>
</div>
<div *ngIf="this.error" class="dna-table-chart--no-data"> <span translate>VIZUALIZATIONS.BOARD.ERROR_ON_CHART </span>
</div>
