import { CustomModalComponent } from '../modals/customModal/custom-modal.component';

declare var require: any;
import { from as observableFrom, of as observableOf, forkJoin, Observable, Subscriber, of, combineLatest } from 'rxjs';

import { map, mergeMap, flatMap, catchError, switchMap } from 'rxjs/operators';
import {
  DatePipe
} from '@angular/common';
import {
  EventEmitter,
  Injectable
} from '@angular/core';
import * as _ from 'lodash';
import {
  NgbModal, NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { v4 as uuid } from 'uuid';

import {
  ActionBarButton,
  ActionTypes,
  ApplicationSide,
  ApplicationSides,
  Arcs,
  ArcsParticipation,
  Block,
  Buttons,
  Campaign,
  CampaignRawData,
  CampaignStates,
  DNAObject,
  DNAType,
  DNATypes,
  Evaluation,
  Formula,
  Graph,
  GroupedFormula,
  Hub,
  HubById,
  KeyValue,
  LightCampaign,
  Menu,
  MenuType,
  ModalContent,
  OnePagerTabs,
  Order,
  Routine2,
  RoutineStep,
  SimComp,
  States,
  SynergyDemand,
  TableHeader,
  Timeline,
  TimelineTypes,
  Training,
  Type,
  User,
  UserInCampaign,
  Visit,
  Volunteers,
  Workflow,
  Workspace,
  DNAComponent,
  Translatable,
  Descriptor,
  DescriptorGroup,
  ImageBlob,
  Image as ImageFromDNA,
  CampaignOnePager,
  CampaignParameters,
  CampaignFormulas,
  CampaignVisit,
  Language,
  HubsById,
  AttributeData,
  Metier,
  Metiers,
  ArcsPanelist,
  __Links,
  Resp,
  CustomModalContent,
  FilterSchedule
} from '../../types';
import {
  ConfirmModalComponent
} from '../modals/confirm/confirm-modal.component';
import {
  DNATranslateService
} from './translate.service';
import {
  ErrorManagerService
} from './errorManager.service';
import {
  ReferenceTypeService
} from './reference-type.service';
import {
  UserService
} from './user.service';
import { WorkspaceService } from '@app/general/my-profile/workspaces/workspaces.service';
import { HttpRestService } from './httpRest.service';
import { Preferences } from '../../../../../DNA_MobileAppV2/src/app/type';

import {
  environment
} from '@env';

import { Router } from '@angular/router';
import { LocalStorageService } from './localStorage.service';
import * as i18nIsoCountries from 'i18n-iso-countries';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/es.json'));
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/ja.json'));
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/pt.json'));
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/zh.json'));


@Injectable()
export class UtilService {
  public onePagerTabs = OnePagerTabs;
  hubs: Hub[] = [];
  updateMenu: EventEmitter<any> = new EventEmitter();
  public isOnOnePagerImage = new EventEmitter();
  public isAnalyseGraphEdit: EventEmitter<boolean> = new EventEmitter();
  serverUrlStudio: string;

  constructor(
    private datePipe: DatePipe,
    private dnaTranslateService: DNATranslateService,
    private errorManager: ErrorManagerService,
    private modalService: NgbModal,
    private referenceTypeService: ReferenceTypeService,
    private userService: UserService,
    private workspaceService: WorkspaceService,
    private httpRestService: HttpRestService,
    private localStorageService: LocalStorageService

  ) {
    this.hubs = this.referenceTypeService.getHubs();
    this.serverUrlStudio = environment.server_url_studio();
  }

  addDefaultPagesInVisit(visit: CampaignVisit, newLoopCreated: boolean) {
    if (_.isEmpty(_.get(visit, 'pages', [])) || newLoopCreated) {
      visit.pages = visit.blocks.filter(block => block.isActive).map((block, index) => index + 1);
    }
    return visit;
  }

  addNamesToUsers(usersInCampaign: any[], usersInDatabase: User[] = []): UserInCampaign[] {
    return usersInCampaign.map(user => {
      const userInDb = usersInDatabase.find(userDatabase => userDatabase.key == user.key);
      user.name = userInDb ? userInDb.name : '';
      return user;
    });
  }

  addProperty(object: any, property: string, value: any): any {
    if (!_.isEmpty(property)) {
      object[property] = value;
    }
    return object;
  }

  addWorkspaceOnCreate(user: User = new User()): string[] {
    return (user.currentWorkspace && user.currentWorkspace.id) ? [user.currentWorkspace.id] : [];
  }

  calculateCouple(campaignState, formula: GroupedFormula[], formulaCouples: SimComp[], applicationZone: KeyValue, chosenBench: GroupedFormula): SimComp[] {
    let f, newCouples: SimComp[] = [],
      simComp, simpCompInformulaCouples;
    for (let index in formula) {
      f = formula[index];
      const numberOfZones = parseInt(_.get(applicationZone, 'key', '1'));
      for (let i = parseInt(index) + (numberOfZones - 1); i < formula.length; i++) {
        const groupFormulaTab = this.setGroupFormulaTab(f, formula, numberOfZones, i);
        simComp = this.createSimComp(groupFormulaTab, f, chosenBench, numberOfZones);
        simpCompInformulaCouples = formulaCouples.find(c => c.name === simComp.name);
        if (simpCompInformulaCouples && (campaignState === CampaignStates.Draft || campaignState === CampaignStates.Suspended)) {
          simpCompInformulaCouples.isActive = false;
          if (!_.isUndefined(simpCompInformulaCouples.formula.find(l => l.name === chosenBench.name))) {
            simpCompInformulaCouples = this.updateBenchLab(simpCompInformulaCouples, chosenBench);
          }
        }
        newCouples.push(simpCompInformulaCouples ? simpCompInformulaCouples : simComp);
      }
    }
    return newCouples;
  }

  setGroupFormulaTab(f, formula, zoneNumber, index): GroupedFormula[] {
    const groupFormulaTab = [f];
    switch (zoneNumber) {
      case 2:
        groupFormulaTab.push(formula[index]);
        break;
      case 3:
        groupFormulaTab.push(formula[index - 1], formula[index]);
        break;
    }
    return groupFormulaTab;
  }

  createSimComp(groupFormulaTab: GroupedFormula[], formula: GroupedFormula, chosenBench: GroupedFormula, applicationZoneKeyInteger: number) {
    let benchFromGroupFormulaTab = groupFormulaTab.find(gf => gf.name === _.get(chosenBench, 'name', ''));
    const containsChoosenBench = !_.isUndefined(benchFromGroupFormulaTab);
    if (!containsChoosenBench) {
      benchFromGroupFormulaTab = groupFormulaTab.find(gf => gf.name === _.get(formula, 'name', ''));
    }
    const simComp = new SimComp(groupFormulaTab, benchFromGroupFormulaTab, containsChoosenBench, this.generateRandomID());
    let tabNames = [];
    for (let i = 0; i < applicationZoneKeyInteger; i++) {
      if (simComp.formula[i].name !== benchFromGroupFormulaTab.name) {
        tabNames.push(simComp.formula[i].name);
      }
    }
    applicationZoneKeyInteger === 3
      ? tabNames.splice(1, 0, simComp.formula.find(f => f.name === benchFromGroupFormulaTab.name).name)
      : tabNames.splice(0, 0, simComp.formula.find(f => f.name === benchFromGroupFormulaTab.name).name);
    simComp.name = tabNames.join(' - ');
    return simComp;
  }

  updateBenchLab(simpCompInformulaCouples: SimComp, chosenBench: GroupedFormula) {
    let updatedFormulaCouple = _.cloneDeep(simpCompInformulaCouples);
    updatedFormulaCouple.isActive = true;
    updatedFormulaCouple.bench = simpCompInformulaCouples.formula.find(elt => elt.name === chosenBench.name);
    updatedFormulaCouple.lab = _.filter(simpCompInformulaCouples.formula, function (elt) {
      return elt.name !== chosenBench.name;
    });
    return updatedFormulaCouple;
  }

  canDeactivate<T>(object: T, objectOriginal: T): boolean {
    if (!object || _.isEqual(object, objectOriginal)) {
      return true;
    } else {
      this.errorManager.displayMessage('SAVE_MODIFICATION_BEFORE_CONTINUING', 'default');
      return false;
    }
  }

  htmlToText(html: string): string {
    return html.replace(/<[^>]*>?/gm, '');
  }

  checkUnicityData(array: any[] = [], prop: string, id: string): boolean {
    if (!prop) {
      return true;
    }
    for (let data of array) {
      if (data[prop] === id) {
        return false;
      }
    }
    return true;
  }

  createActionsButtons(type: DNAType, condition: boolean = false): Observable<ActionBarButton[]> {
    let translations;
    switch (type) {
      case DNATypes.Block:
      case DNATypes.Workflow:
        translations = [
          this.dnaTranslateService.translateMessage(ActionTypes.Duplicate),
          this.dnaTranslateService.translateMessage(ActionTypes.Remove),
          this.dnaTranslateService.translateMessage(ActionTypes.PutBack)
        ];
        if (this.userService.isAuthorized('DNA_ADMIN')) {
          translations.push(this.dnaTranslateService.translateMessage(ActionTypes.Delete));
        }
        return forkJoin(translations).pipe(mergeMap((translations: string[]) => {
          const obs = [
            new ActionBarButton(ActionTypes.Duplicate, 'fa fa-files-o', translations[0]),
            new ActionBarButton(ActionTypes.Remove, 'fa fa-times', translations[1]),
            new ActionBarButton(ActionTypes.PutBack, 'fa fa-undo', translations[2])
          ];
          if (this.userService.isAuthorized('DNA_ADMIN')) {
            obs.push(new ActionBarButton(ActionTypes.Delete, 'fa fa-exclamation-triangle', translations[3]));
          }
          return observableOf(obs);
        }));

      case DNATypes.CampaignsMulti:
        translations = [
          this.dnaTranslateService.translateMessage(ActionTypes.Delete),
        ];
        return forkJoin(translations).pipe(mergeMap((translations: string[]) => {
          const obs = [
            new ActionBarButton(ActionTypes.Delete, 'fa fa-exclamation-triangle', translations[0]),
          ];
          return observableOf(obs);
        }));
      case DNATypes.Campaign:
        translations = [
          this.dnaTranslateService.translateMessage(ActionTypes.Duplicate),
          this.dnaTranslateService.translateMessage(ActionTypes.Remove),
          this.dnaTranslateService.translateMessage(ActionTypes.PutBack),
          this.dnaTranslateService.translateMessage(ActionTypes.Delete),
          this.dnaTranslateService.translateMessage(ActionTypes.ExportConfort)
        ];
        return forkJoin(translations).pipe(mergeMap((translations: string[]) => {
          const obs = [
            new ActionBarButton(ActionTypes.Duplicate, 'fa fa-files-o', translations[0]),
            new ActionBarButton(ActionTypes.Remove, 'fa fa-times', translations[1]),
            new ActionBarButton(ActionTypes.PutBack, 'fa fa-undo', translations[2]),
          ];
          if (this.userService.isAuthorized('DNA_ADMIN')) {
            obs.push(new ActionBarButton(ActionTypes.Delete, 'fa fa-exclamation-triangle', translations[3]));
          }
          if (this.userService.isConfort()) {
            obs.push(new ActionBarButton(ActionTypes.ExportConfort, 'fa fa-download', translations[4]));
          }
          return observableOf(obs);
        }));
      case DNATypes.SynergyDemand:
        if (this.userService.isAuthorized('DNA_SCHEDULE')) {
          if (condition) {
            return forkJoin(
              this.dnaTranslateService.translateMessage(ActionTypes.EditSynergyDemand),
              this.dnaTranslateService.translateMessage(ActionTypes.GoToCampaign),
              this.dnaTranslateService.translateMessage(ActionTypes.UnlinkSynergyDemand)
            ).pipe(mergeMap((translations: string[]) => {
              return observableOf([
                new ActionBarButton(ActionTypes.EditSynergyDemand, 'fa fa-pencil-square-o', translations[0]),
                new ActionBarButton(ActionTypes.GoToCampaign, 'fa fa-sign-in', translations[1]),
                new ActionBarButton(ActionTypes.UnlinkSynergyDemand, 'fa fa-chain-broken', translations[2])
              ]);
            }));
          } else {
            return forkJoin(
              this.dnaTranslateService.translateMessage(ActionTypes.EditSynergyDemand),
              this.dnaTranslateService.translateMessage(ActionTypes.CreateCampaign),
              this.dnaTranslateService.translateMessage(ActionTypes.LinkCampaign)
            ).pipe(mergeMap((translations: string[]) => {
              return observableOf([
                new ActionBarButton(ActionTypes.EditSynergyDemand, 'fa fa-pencil-square-o', translations[0]),
                new ActionBarButton(ActionTypes.CreateCampaign, 'fa fa-plus-square-o', translations[1]),
                new ActionBarButton(ActionTypes.LinkCampaign, 'fa fa-link', translations[2])
              ]);
            }));
          }
        } else {
          return observableOf([]);
        }

      case DNATypes.Training:
        if (this.userService.isAuthorized('DNA_ADMIN')) {
          return this.dnaTranslateService.translateMessage(ActionTypes.Remove).pipe(
            mergeMap((translation: string) => {
              return observableOf([
                new ActionBarButton(ActionTypes.Remove, 'fa fa-times', translation)
              ]);
            }));
        } else {
          return observableOf([]);
        }

      case DNATypes.Volunteers:
        return this.dnaTranslateService.translateMessage(ActionTypes.Remove).pipe(
          mergeMap((translation: string) => {
            return observableOf([
              new ActionBarButton(ActionTypes.Remove, 'fa fa-times', translation)
            ]);
          }));

      case DNATypes.Workspace:
        return forkJoin(
          this.dnaTranslateService.translateMessage(ActionTypes.Edit),
          this.dnaTranslateService.translateMessage(ActionTypes.EditUsersPreferences),
          this.dnaTranslateService.translateMessage('BLOCKS').pipe(
            mergeMap(translation => {
              return this.dnaTranslateService.translateMessage(ActionTypes.EditList, {
                'object': translation.toString().toLowerCase()
              });
            })),
          this.dnaTranslateService.translateMessage('WORKFLOWS').pipe(
            mergeMap(translation => {
              return this.dnaTranslateService.translateMessage(ActionTypes.EditList, {
                'object': translation.toString().toLowerCase()
              });
            })),
          this.dnaTranslateService.translateMessage('CAMPAIGNS').pipe(
            mergeMap(translation => {
              return this.dnaTranslateService.translateMessage(ActionTypes.EditList, {
                'object': translation.toString().toLowerCase()
              });
            })),
          this.dnaTranslateService.translateMessage('CHARTS_SETTINGS'),
          this.dnaTranslateService.translateMessage(ActionTypes.Remove)
        ).pipe(mergeMap((translations: string[]) => {
          return observableOf([
            new ActionBarButton(ActionTypes.Edit, 'fa fa-pencil', translations[0]),
            new ActionBarButton(ActionTypes.EditUsersPreferences, 'fa fa-cog', translations[1]),
            new ActionBarButton(ActionTypes.EditListBlocks, 'fa fa-list', translations[2]),
            new ActionBarButton(ActionTypes.EditListWorkflows, 'fa fa-clipboard', translations[3]),
            new ActionBarButton(ActionTypes.EditListCampaigns, 'fa fa-calendar', translations[4]),
            new ActionBarButton(ActionTypes.EditChartsSettings, 'fa fa-line-chart', translations[5]),
            new ActionBarButton(ActionTypes.Remove, 'fa fa-times', translations[6])
          ]);
        }));
      case DNATypes.Evaluation:
        return this.dnaTranslateService.translateMessage(ActionTypes.GoToMobile).pipe(
          mergeMap((translation: string) => {
            return observableOf([
              new ActionBarButton(ActionTypes.GoToMobile, 'fa fa-link', translation)
            ]);
          }));
    }
  }

  createButtonObject(idsButtons: string[], translations: string[], activeButtons: boolean[], styleButtons: string[]): Observable<Buttons[]> {
    return observableOf(translations.map((translation, index) => {
      return {
        id: idsButtons[index],
        name: translation,
        isActive: activeButtons[index],
        style: styleButtons[index]
      };
    }));
  }

  createButtonsAssessments(activeButtons: boolean[]) {
    let keyTranslations = ['EXPORT_EXCEL', 'DELETE'];
    let idsButtons = ['EXPORT_EXCEL', 'DELETE'];
    let styleButtons = ['btn-primary', 'btn-danger'];
    return forkJoin(
      this.translate(keyTranslations)
    ).pipe(mergeMap(translationsLabels => {
      return this.createButtonObject(idsButtons, translationsLabels, activeButtons, styleButtons);
    }));
  }

  createButtonsDetailEvaluation(activeButtons: boolean[]) {
    let keyTranslations = ['DOWNLOAD_IMAGES', 'DOWNLOAD_VIDEOS'];
    let idsButtons = ['DOWNLOAD_IMAGES', 'DOWNLOAD_VIDEOS'];
    let styleButtons = ['btn-primary', 'btn-primary'];
    return forkJoin(
      this.translate(keyTranslations)
    ).pipe(mergeMap(translationsLabels => {
      return this.createButtonObject(idsButtons, translationsLabels, activeButtons, styleButtons);
    }));
  }

  createButtonsEvaluations(activeButtons: boolean[]) {
    let keyTranslations = [];
    let idsButtons = [];
    let styleButtons = [];
    if (activeButtons[0]) {
      keyTranslations.push('EXPORT_CONFORT');
      idsButtons.push('EXPORT_CONFORT');
    } else {
      activeButtons.shift();
    }
    keyTranslations = keyTranslations.concat(['DOWNLOAD_IMAGES', 'DOWNLOAD_VIDEOS']);
    idsButtons = idsButtons.concat(['DOWNLOAD_IMAGES', 'DOWNLOAD_VIDEOS']);
    keyTranslations.map(() => styleButtons.push('btn-primary'));
    return forkJoin(
      this.translate(keyTranslations)
    ).pipe(mergeMap(translationsLabels => {
      return this.createButtonObject(idsButtons, translationsLabels, activeButtons, styleButtons);
    }));
  }

  createButtonsEvaluationsMulti(activeButtons: boolean[]) {
    let keyTranslations = ['DOWNLOAD_IMAGES', 'DOWNLOAD_VIDEOS'];
    let idsButtons = ['DOWNLOAD_IMAGES', 'DOWNLOAD_VIDEOS'];
    let styleButtons = ['btn-primary', 'btn-primary'];
    return forkJoin(
      this.translate(keyTranslations)
    ).pipe(mergeMap(translationsLabels => {
      return this.createButtonObject(idsButtons, translationsLabels, activeButtons, styleButtons);
    }));
  }

  createHeaderObject(translations: string[], isSortable: boolean[], icons: string[], ids: string[]): Observable<TableHeader[]> {
    return observableOf(translations.map((t, index) => {
      return {
        name: t,
        icon: icons[index],
        sortable: isSortable[index],
        id: ids[index]
      };
    }));
  }

  createHeaders(DNAType: DNAType): Observable<TableHeader[]> {
    const iconPath = '/assets/pc_icon.svg';
    switch (DNAType) {
      case DNATypes.Block:
        return forkJoin(
          this.translate(['NAME', 'STATE', 'DATE_CREATION', 'DATE_MODIFICATION', 'ACTIONS'])
        ).pipe(mergeMap(translations => {
          return this.createHeaderObject(translations, [true, true, true, true, false], ['', '', '', '', ''], ['NAME', 'STATE', 'DATE_CREATION', 'DATE_MODIFICATION', 'ACTIONS']);
        }));
      case DNATypes.Workflow:
        return forkJoin(
          this.translate(['NAME', 'STATE', 'HUB', 'DATE_CREATION', 'DATE_MODIFICATION', 'ACTIONS'])
        ).pipe(mergeMap(translations => {
          return this.createHeaderObject(translations, [true, true, true, true, true, false], ['', '', '', '', '', ''], ['NAME', 'STATE', 'HUB', 'DATE_CREATION', 'DATE_MODIFICATION', 'ACTIONS']);
        }));
      case DNATypes.SynergyDemand:
        return forkJoin(
          this.translate(['ID', 'BRIDGE_STATE', 'PRIORITY', 'CAMPAIGN_MANAGER', 'CAMPAIGN_REFERENT', 'BRIDGE_REQUESTER', 'ACTIONS'])
        ).pipe(mergeMap(translations => {
          return this.createHeaderObject(translations, [true, true, true, true, true, true, false], ['', '', '', '', '', '', ''], ['ID', 'BRIDGE_STATE', 'PRIORITY', 'CAMPAIGN_MANAGER', 'CAMPAIGN_REFERENT', 'BRIDGE_REQUESTER', 'ACTIONS']);
        }));
      case DNATypes.Campaign:
        return forkJoin(
          this.translate(['', 'NAME', 'STATE', 'WORKFLOWS', 'FORMULAS', 'ORCHESTRA', '', 'ACTIVIEW', 'BRIDGE', 'CONTRIBUTORS', '', '', 'DATE_MODIFICATION', 'ACTIONS'])
        ).pipe(mergeMap(translations => {
          return this.createHeaderObject(translations, [false, true, true, false, false, true, false, true, true, false, false, false, true, false], ['', '', '', '', '', '', iconPath, '', '', '', 'fa fa-spinner', 'fa fa-check-square-o', '', ''], ['', 'NAME', 'STATE', 'WORKFLOWS', 'FORMULAS', 'ORCHESTRA', '', 'ACTIVIEW', 'BRIDGE', 'CONTRIBUTORS', '', '', 'DATE_MODIFICATION', 'ACTIONS']);
        }));
      case DNATypes.CampaignsMulti:
        return forkJoin(
          this.translate(['NAME', 'CAMPAIGNS', 'WORKFLOWS', 'FORMULAS', 'ORCHESTRA', 'ACTIVIEW', 'BRIDGE', 'DATE_MODIFICATION', 'ACTIONS'])
        ).pipe(mergeMap(translations => {
          return this.createHeaderObject(translations, [true, false, false, false, false, false, false, true, false], ['', '', '', '', '', '', '', '', ''], ['NAME', 'CAMPAIGNS', 'WORKFLOWS', 'FORMULAS', 'ORCHESTRA', 'ACTIVIEW', 'BRIDGE', 'DATE_MODIFICATION', 'ACTIONS']);
        }));
      case DNATypes.CampaignExport:
        return forkJoin(
          this.translate(['','NAME', 'STATE', 'ORCHESTRA', 'ACTIVIEW', 'BRIDGE', 'DATE_MODIFICATION'])
        ).pipe(mergeMap(translations => {
          return this.createHeaderObject(translations, [true, true, true, true, true, true], ['', '', '', '', '', ''], ['NAME', 'STATE', 'ORCHESTRA', 'ACTIVIEW', 'BRIDGE', 'DATE_MODIFICATION']);
        }));
      case DNATypes.Training:
        return forkJoin(
          this.translate(['NAME', 'STATE', 'PROGRESSION', 'DATE_CREATION', 'DATE_MODIFICATION', 'ACTIONS'])
        ).pipe(mergeMap(translations => {
          return this.createHeaderObject(translations, [true, true, true, true, true, false], ['', '', '', '', '', ''], ['NAME', 'STATE', 'PROGRESSION', 'DATE_CREATION', 'DATE_MODIFICATION', 'ACTIONS']);
        }));
      case DNATypes.Volunteers:
        return forkJoin(
          this.translate(['VOLUNTEER', 'ACTIONS'])
        ).pipe(mergeMap(translations => {
          return this.createHeaderObject(translations, [true, false], ['', ''], ['VOLUNTEER', 'ACTIONS']);
        }));
        case DNATypes.Panelists:
          return forkJoin(
            this.translate(['TYPE', 'DATE_ENTRY', 'PROJECTS.PROJECT_BRIDGE', 'PROJECT.PROJECT_NUMBER', 'ID_VOLUNTEER', 'FIRST_NAME', 'LAST_NAME', 'ORCHESTRA_NUMBER', 'LABORATORY'])
          ).pipe(mergeMap(translations => {
            return this.createHeaderObject(translations, [true, true, true, true, true, true, true, true, true], ['', '', '', '', '', '', '', '', ''], ['TYPE', 'DATE_ENTRY', 'PROJECTS.PROJECT_BRIDGE', 'PROJECT.PROJECT_NUMBER', 'ID_VOLUNTEER', 'FIRST_NAME', 'LAST_NAME', 'ORCHESTRA_NUMBER', 'LABORATORY']);
          }));
        case DNATypes.PanelistsArcs:
          return forkJoin(
            this.translate(['TYPE', 'DATE_ENTRY', 'STUDY_NUMBER', 'STUDY_NAME'])
          ).pipe(mergeMap(translations => {
            return this.createHeaderObject(translations, [true, true, true, true], ['', '', '', ''], ['TYPE', 'DATE_ENTRY', 'STUDY_NUMBER', 'STUDY_NAME']);
          }));
        case DNATypes.GroupPanelistsHA:
          return forkJoin(
            this.translate(['NAME', 'DATE_ENTRY', 'HUB', 'NUMBER_OF_VOLUNTEERS'])
          ).pipe(mergeMap(translations => {
            return this.createHeaderObject(translations, [true, true, true, true], ['', '', '', ''], ['NAME', 'DATE_ENTRY', 'HUB', 'NUMBER_OF_VOLUNTEERS']);
          }));
      case DNATypes.Descriptors:
        return forkJoin(
          this.translate(['STUDY', 'WORKFLOW', 'BLOCK', 'DESCRIPTOR', 'SCALE', 'PERTINANCE'])
        ).pipe(mergeMap(translations => {
          return this.createHeaderObject(translations, [true, true, true, true, true, true], ['', '', '', '', '', ''], ['STUDY', 'WORKFLOW', 'BLOCK', 'DESCRIPTOR', 'SCALE', 'PERTINANCE']);
        }));
      case DNATypes.Feedbacks:
        return forkJoin(
          this.translate(['TITRE', 'SOURCE', 'COMMENT', 'ACTION'])
        ).pipe(mergeMap(translations => {
          return this.createHeaderObject(translations, [true, true, false, false], ['', '', '', ''], ['TITLE', 'SOURCE', 'COMMENTS', 'ACTION']);
        }));
    }
  }

  createHeadersAssessments(training: Training) {
    return forkJoin(
      this.translate(['ID', 'WORKFLOW', 'FORMULA', 'USERS', 'PARTICIPANT', 'SESSION', 'PROGRESSION'])
    ).pipe(mergeMap(translations => {
      return this.createHeaderObject(translations, [true, true, true, true, true, true], ['', '', '', '', '', ''], ['ID', 'WORKFLOW', 'FORMULA', 'USERS', 'VOLUNTEER', 'PROGRESSION']);
    }));
  }

  createHeadersDetailAssessment() {
    let array = ['INDEX', 'LABEL', 'PARTICIPANT', 'ANSWER', 'FEEDBACK'];
    let isSortable = [true, true, false, false, false];
    let icons = ['', '', '', '', ''];
    return forkJoin(
      this.translate(array)
    ).pipe(mergeMap(translations => {
      return this.createHeaderObject(translations, isSortable, icons, array);
    }));
  }

  createHeadersDetailEvaluation(evaluation: Evaluation = new Evaluation()) {
    let array, icons, isSortable;
    if (evaluation.volunteer) {
      array = ['INDEX', 'LABEL', 'USER', 'VOLUNTEER', 'ANSWER', 'FEEDBACK'];
      isSortable = [true, true, true, false, false, false];
      icons = ['', '', '', '', '', ''];
    } else {
      array = ['INDEX', 'LABEL', 'USER', 'ANSWER', 'FEEDBACK'];
      isSortable = [true, true, true, false, false];
      icons = ['', '', '', '', ''];
    }

    return forkJoin(
      this.translate(array)
    ).pipe(mergeMap(translations => {
      return this.createHeaderObject(translations, isSortable, icons, array);
    }));
  }

  createHeadersDropUser() {
    let array = ['USERS', 'ACTIVE'];
    return forkJoin(
      this.translate(array)
    ).pipe(mergeMap(translations => {
      return this.createHeaderObject(translations, [true, false], array.map(a => ''), array);
    }));
  }

  createHeadersDropVolunteer() {
    let array = ['VOLUNTEERS', 'ACTIVE'];
    return forkJoin(
      this.translate(array)
    ).pipe(
      flatMap(translations => this.createHeaderObject(translations, [true, false], array.map(a => ''), array)
      )
    );
  }

  createHeadersEvaluation(campaignRawData: CampaignRawData, hasAction) {
    const array = campaignRawData.isVolunteer ? ['CAMPAIGN', 'WORKFLOW', 'FORMULA', 'VOLUNTEER', 'USERS', 'PROGRESSION', 'START_DATE'] : ['CAMPAIGN', 'WORKFLOW', 'FORMULA', 'USERS', 'PROGRESSION', 'START_DATE'];
    if (hasAction) {
      array.push('ACTIONS');
    }
    return forkJoin(
      this.translate(array)
    ).pipe(mergeMap(translations => {
      return this.createHeaderObject(translations, array.map(a => true), array.map(a => ''), array);
    }));
  }

  createHeadersEvaluationMulti(campaigns: Campaign[]) {
    let isVolunteer = campaigns.some((campaign: Campaign) => {
      return campaign.users.isVolunteer;
    });

    let array = isVolunteer ? ['CAMPAIGN', 'WORKFLOW', 'FORMULA', 'USERS', 'VOLUNTEER', 'PROGRESSION', 'START_DATE'] : ['CAMPAIGN', 'WORKFLOW', 'FORMULA', 'USERS', 'PROGRESSION', 'START_DATE'];
    return forkJoin(
      this.translate(array)
    ).pipe(mergeMap(translations => {
      return this.createHeaderObject(translations, array.map(a => true), array.map(a => ''), array);
    }));
  }

  createMenuProfile(userIsAdministrator, userIsWorkspaceAdministrator, userIsBlocks) {
    if (userIsAdministrator) {
      return forkJoin(
        this.dnaTranslateService.translateMessage('USER'),
        this.dnaTranslateService.translateMessage('WORKSPACES'),
        this.dnaTranslateService.translateMessage('VOLUNTEERS_MANAGEMENT'),
        this.dnaTranslateService.translateMessage('IMPORT_EXPORT'),
        this.dnaTranslateService.translateMessage('FEEDBACK'),
        this.dnaTranslateService.translateMessage('TRANSLATIONS_MANAGEMENT')
      ).pipe(mergeMap(translations => {
        return observableOf([
          new Menu(['/myprofile', 'identity'], translations[0], 'fa fa-info'),
          new Menu(['/myprofile', 'workspaces'], translations[1], 'fa fa-users'),
          new Menu(['/myprofile', 'volunteers-management'], translations[2], 'fa fa-gears'),
          new Menu(['/myprofile', 'import-export'], translations[3], 'fa fa-exchange'),
          new Menu(['/myprofile', 'feedback'], translations[4], 'fa fa-comments'),
          new Menu(['/myprofile', 'translations'], translations[5], 'fa fa-cog')
        ]);
      }));
    } else {
      return forkJoin(
        this.dnaTranslateService.translateMessage('USER'),
        this.dnaTranslateService.translateMessage('WORKSPACES'),
        this.dnaTranslateService.translateMessage('TRANSLATIONS_MANAGEMENT')
      ).pipe(mergeMap(translations => {
        const menu = [new Menu(['/myprofile', 'identity'], translations[0], 'fa fa-info')];
        if (userIsWorkspaceAdministrator) {
          menu.push(new Menu(['/myprofile', 'workspaces'], translations[1], 'fa fa-users'));
        }
        if (userIsBlocks) {
          menu.push(new Menu(['/myprofile', 'translations'], translations[2], 'fa fa-cog'));
        }
        return observableOf(menu);
      }));
    }
  }

  createMenu(type: DNAType): Observable<Menu[]> {
    let i = 0;
    switch (type) {

      case DNATypes.CampaignsMulti:
        return combineLatest([
          this.dnaTranslateService.translateMessage('GENERAL'),
          this.dnaTranslateService.translateMessage('PROTOCOL'),
          this.dnaTranslateService.translateMessage('TARGET'),
          this.dnaTranslateService.translateMessage('STUDIES'),
          this.dnaTranslateService.translateMessage('WORKFLOWS'),
          this.dnaTranslateService.translateMessage('USERS'),
          this.dnaTranslateService.translateMessage('FORMULAS_ROUTINES'),
          this.dnaTranslateService.translateMessage('DESCRIPTOR_ASSOCIATION'),
          this.dnaTranslateService.translateMessage('ANALYTICS'),
          this.dnaTranslateService.translateMessage('ONE_PAGER')
        ]).pipe(
          map(translations => {
            let i = 0;
            return [
              new Menu(['generalInformation'], translations[i++], 'fa fa-info-circle'),
              new Menu(['protocol'], translations[i++], 'fa fa-retweet'),
              new Menu(['target'], translations[i++], 'fa fa-bullseye'),
              new Menu(['studies'], translations[i++], 'fa fa-list'),
              new Menu(['workflows'], translations[i++], 'fa fa-clipboard'),
              new Menu(['users'], translations[i++], 'fa fa-user'),
              new Menu(['formulas'], translations[i++], 'fa fa-flask'),
              new Menu(['descriptors'], translations[i++], 'fa fa-link'),
              new Menu(['analyse', 'reports'], translations[i++], 'fa fa-line-chart'),
              new Menu(['onepager'], translations[i++], 'fa fa-file-pdf-o', MenuType.OnePager)
            ];
          })
        );

      case DNATypes.Campaign:
        return combineLatest([
          this.dnaTranslateService.translateMessage('GENERAL'),
          this.dnaTranslateService.translateMessage('PROTOCOL'),
          this.dnaTranslateService.translateMessage('WORKFLOWS'),
          this.dnaTranslateService.translateMessage('VISITS'),
          this.dnaTranslateService.translateMessage('DISPLAY_MODE'),
          this.dnaTranslateService.translateMessage('TARGET'),
          this.dnaTranslateService.translateMessage('USERS'),
          this.dnaTranslateService.translateMessage('FORMULAS_ROUTINES'),
          this.dnaTranslateService.translateMessage('PARAMETERS'),
          this.dnaTranslateService.translateMessage('PUBLICATION'),
          this.dnaTranslateService.translateMessage('RAW_DATA'),
          this.dnaTranslateService.translateMessage('ANALYTICS'),
          this.dnaTranslateService.translateMessage('ONE_PAGER'),
          this.dnaTranslateService.translateMessage('PHOTOS')
        ]).pipe(
          map(translations => {
            let i = 0;
            const menuCampaign = [
              new Menu(['edit', 'generalInformation'], translations[i++], 'fa fa-info-circle'),
              new Menu(['edit', 'protocol'], translations[i++], 'fa fa-retweet'),
              new Menu(['edit', 'workflows'], translations[i++], 'fa fa-clipboard'),
              new Menu(['edit', 'visits'], translations[i++], 'fa fa-repeat'),
              new Menu(['edit', 'displayMode'], translations[i++], 'fa fa-eye'),
              new Menu(['edit', 'target'], translations[i++], 'fa fa-bullseye'),
              new Menu(['edit', 'users'], translations[i++], 'fa fa-user'),
              new Menu(['edit', 'formulas'], translations[i++], 'fa fa-flask'),
              new Menu(['edit', 'parameters'], translations[i++], 'fa fa-cog'),
              new Menu(['edit', 'publish'], translations[i++], 'fa fa-paper-plane-o'),
              new Menu(['edit', 'rawdata'], translations[i++], 'fa fa-table'),
              new Menu(['edit', 'analyse'], translations[i++], 'fa fa-line-chart'),
              new Menu(['edit', 'onepager'], translations[i++], 'fa fa-file-pdf-o', MenuType.OnePager),
              new Menu(['edit', 'photos'], translations[i++], 'fa fa-camera')
            ];
            return menuCampaign;
          })
        );

        case DNATypes.Template:

        const isAuthorizedForTemplate = (): boolean => {
          const authorizedRoles = ['DNA_SCHEDULE', 'DNA_ADMIN', 'DNA_DEV'];
          const user = this.userService.getUser();
          return user.roles.some(role => authorizedRoles.includes(role));
        };

        if (isAuthorizedForTemplate()) {
          const translations$ = [
            this.dnaTranslateService.translateMessage('GENERAL'),
            this.dnaTranslateService.translateMessage('PROTOCOL'),
            this.dnaTranslateService.translateMessage('WORKFLOWS'),
            this.dnaTranslateService.translateMessage('VISITS'),
            this.dnaTranslateService.translateMessage('DISPLAY_MODE'),
            this.dnaTranslateService.translateMessage('TARGET'),
            this.dnaTranslateService.translateMessage('PUBLICATION')
          ];

          return combineLatest(translations$).pipe(
            map(translations => {
              let i = 0;
              const menuTemplate = [
                new Menu(['edit', 'generalInformation'], translations[i++], 'fa fa-info-circle'),
                new Menu(['edit', 'protocol'], translations[i++], 'fa fa-retweet'),
                new Menu(['edit', 'workflows'], translations[i++], 'fa fa-clipboard'),
                new Menu(['edit', 'visits'], translations[i++], 'fa fa-repeat'),
                new Menu(['edit', 'displayMode'], translations[i++], 'fa fa-eye'),
                new Menu(['edit', 'target'], translations[i++], 'fa fa-bullseye'),
                new Menu(['edit', 'publish'], translations[i++], 'fa fa-paper-plane-o'),
              ];

              return menuTemplate;
            })
          );
        } else {
          console.log('User is not authorized for the template');
          return of([]);
        }

      case DNATypes.Training:
        return combineLatest([
          this.dnaTranslateService.translateMessage('GENERAL'),
          this.dnaTranslateService.translateMessage('WORKFLOWS'),
          this.dnaTranslateService.translateMessage('USERS'),
          this.dnaTranslateService.translateMessage('FORMULAS'),
          this.dnaTranslateService.translateMessage('PARAMETERS'),
          this.dnaTranslateService.translateMessage('PUBLICATION'),
          this.dnaTranslateService.translateMessage('RAW_DATA'),
          this.dnaTranslateService.translateMessage('ANALYTICS')
        ]).pipe(
          map(translations => {
            let i = 0;
            return [
              new Menu(['edit', 'generalInformation'], translations[i++], 'fa fa-info-circle'),
              new Menu(['edit', 'workflows'], translations[i++], 'fa fa-clipboard'),
              new Menu(['edit', 'users'], translations[i++], 'fa fa-user'),
              new Menu(['edit', 'formulas'], translations[i++], 'fa fa-flask'),
              new Menu(['edit', 'parameters'], translations[i++], 'fa fa-cog'),
              new Menu(['edit', 'publish'], translations[i++], 'fa fa-paper-plane-o'),
              new Menu(['edit', 'rawdata'], translations[i++], 'fa fa-table'),
              new Menu(['edit', 'analyse'], translations[i++], 'fa fa-line-chart')
            ];
          })
        );
      }
  }

  differentKeys<T>(currentObject: T, originalObject: T): boolean {
    return this.isDifferent(this.getKeys(currentObject), this.getKeys(originalObject));
  }

  filterByArchive(state: States | CampaignStates): (array: ActionBarButton[]) => ActionBarButton[] {
    return array => array.filter(data => data.id !== (state === States.Removed ? ActionTypes.Remove : ActionTypes.PutBack));
  }

  filterByCurrentUser(users: UserInCampaign[], filterOn: boolean = true): boolean {
    if (!filterOn) {
      return true;
    }
    return users.findIndex((user: UserInCampaign) => user.key === this.userService.getUser().track) !== -1;
  }

  filterByFavorites(objet: DNAObject, filterOn: boolean): boolean {
    return !(filterOn && !this.userService.isFavorite(objet.id));
  }

  filterByFiche(fiche: string = '', ficheFromFilter: string = ''): boolean {
    return (ficheFromFilter === TimelineTypes.ALL_FICHE || ficheFromFilter === fiche) ? true : false;

  }

  filterByFormulas(formula: Formula[], formulasFromFilter: any[] = []): boolean {
    return formulasFromFilter.length === 0 ? true :
      formulasFromFilter.some(
        f => formula ? formula.some((group: GroupedFormula) => group.name === f.name) : false
      );
  }

  filterByHubs(hub: Hub, hubsFromFilter: Hub[] = []): boolean {
    /**
     * Cases:
     * All hubs selected -> All campaigns displayed (with and without hub)
     * Some hubs selected -> Only campaigns with hub (one of the selected) are dispayed
     * No hubs selected -> Only campaigns without hubs are displayed
     */
    return hubsFromFilter.length === this.hubs.length ? true :
      (hubsFromFilter.length > 0 ? _.includes(hubsFromFilter, hub) : (hub ? false : true));
  }

  filterById(items, itemsFromFilter) {
    return itemsFromFilter.length === 0 ? true :
      itemsFromFilter.some(i => items.some(item => (item.Id === i.Id && item.IsValue === true)));
  }

  filterByKeyValueItems(items: KeyValue[], itemsFromFilter: KeyValue[]) {
    if (_.isArray(items)) {
      let itemsKey = items.map(itm => itm ? itm.key : undefined);
      return itemsFromFilter.length === 0 ? true :
        itemsFromFilter.every(item => _.includes(itemsKey, item.key));
    }
    return itemsFromFilter.length === 0;
  }

  filterByNumber(number: number = null, numberFromFilter: number = null): boolean {
    return numberFromFilter ? number === numberFromFilter : true;
  }

  filterByStartDates(startDate: any, startDateFromFilter: any): boolean {
    if (startDateFromFilter && startDate) {
      const date = startDateFromFilter.getTime();
      if (_.isNaN(date)) {
        return true;
      }
      return date <= startDate;
    } else {
      return true;
    }
  }

  filterByEndDates(endDate: any, endDateFromFilter: any): boolean {
    if (endDateFromFilter && endDate) {
      const dateToCompare = new Date(endDateFromFilter);
      dateToCompare.setHours(24);
      const date = dateToCompare.getTime();
      if (_.isNaN(date)) {
        return true;
      }
      return date >= endDate;
    }
    return true;
  }

  filterByState(state: string = '', statesFromFilter: string[] = []): boolean {
    return _.includes(statesFromFilter, state);
  }

  filterByTemplate(campaign: LightCampaign, filterOn: boolean): boolean {
    return !(filterOn && !campaign.isTemplate);
  }

  filterByTests(objet: DNAObject, filterOn: boolean): boolean {
    return !(filterOn && !this.userService.isTest(objet));
  }

  filterByText(text: string = '', textFromFilter: string = ''): boolean {
    return text.toLowerCase().indexOf(textFromFilter.toLowerCase()) !== -1;
  }

  filterByTextArray(arrayOfText: string[] = [], textFromFilter: string = ''): boolean {
    return (arrayOfText.length === 0 && !textFromFilter) ? true :
      arrayOfText.findIndex(text => this.filterByText(text, textFromFilter)) !== -1;
  }

  filterByType(type: Type, typesFromFilter: Type[] = []): boolean {
    if (!type) {
      return true;
    }
    return typesFromFilter.findIndex((t: Type) => t.id === type.id) > -1;
  }

  filterByUsers(users: UserInCampaign[], usersFromFilter: UserInCampaign[]): boolean {
    if (_.isEmpty(usersFromFilter)) {
      return true;
    }
    return usersFromFilter.some(
      (userFromFilter: UserInCampaign) => users ? users.some((user: UserInCampaign) => _.get(user, 'key', '') === _.get(userFromFilter, 'key', '')) : false
    );
  }

  filterByWorkflows(workflows: Workflow[] = [], workflowsFromFilter: any[] = [], language: string = 'english'): boolean {
    return workflowsFromFilter.length === 0 ? true :
      workflowsFromFilter.some(
        wF => workflows.some((w: Workflow) => (w.name[language] || w.name.english) === wF.name)
      );
  }

  filterIsEmpty(filter: any): boolean {
    let isEmpty = true;
    _.forEach(filter, key => {
      if (typeof key !== 'undefined' && key !== null && (typeof key.length === 'undefined' || key.length != 0)) {
        isEmpty = false;
      }
    });
    return isEmpty;
  }

  filterList(objects: any[], filter: any, currentLanguage: string = 'english'): any[] {
    return !objects.filter ? objects :
      objects.filter(
        (object: any) => object.name &&
          this.filterByText(object.name[currentLanguage] || object.name.english, filter.name) &&
          this.filterByState(object.state, filter.states) &&
          this.filterByFavorites(object, filter.displayFavoritesOnly) &&
          this.filterByTests(object, filter.displayTestsOnly)
      );
  }

  filterListCampaign(campaigns: LightCampaign[] = [], filter: any): any[] {
    return campaigns.filter(
      (campaign: any) =>
        this.filterByFavorites(campaign, filter.displayFavoritesOnly) &&
        this.filterByCurrentUser(campaign.accountables, filter.byUser) &&
        this.filterByTemplate(campaign, filter.displayTemplate) &&
        this.filterByStartDates(campaign.startDate, filter.startDate) &&
        this.filterByEndDates(campaign.endDate, filter.endDate)
    );
  }

  filterListCampaignsInDashboard(campaigns: LightCampaign[] = [], filter: any): any[] {
    return campaigns.filter(
      (campaign: LightCampaign) => {
        const allUsers = this.prepareFilterUsers(campaign.accountables, _.get(campaign, 'synergy.manager', ''));
        return this.filterByHubs(campaign.hub, filter.hubs) && this.filterAllUsers(filter, allUsers);
      }
    );
  }

  filterListSynergyDemand(campaigns: LightCampaign[], filter: any): LightCampaign[] {
    return campaigns.filter(
      (campaign: LightCampaign) =>
        (this.filterByText(campaign.name, filter.name) || this.filterByText(campaign.synergy ? campaign.synergy.requestNumber : '', filter.name)) &&
        this.filterByState(campaign.state, filter.states) &&
        this.filterByType(campaign.studyType, filter.studyType) &&
        this.filterByType(campaign.evaluationType, filter.typeEvaluation) &&
        this.filterByStartDates(campaign.startDate, filter.startDate) &&
        this.filterByEndDates(campaign.endDate, filter.endDate)
    );
  }

  filterListSynergyDemandsInDashboard(demands: SynergyDemand[] = [], filter: any): any[] {
    return demands.filter((demand: SynergyDemand) => {
      let displayDemandsNotLinked = true;
      if (filter.displayDemandsNotLinked) {
        displayDemandsNotLinked = !demand.studyId;
      }
      const allUsers = this.prepareFilterUsers(demand.contributors, _.get(demand, 'manager', ''));
      return displayDemandsNotLinked && this.filterAllUsers(filter, allUsers);
    }
    );
  }

  filterAllUsers(filter: any, usersTab: UserInCampaign[]) {
    return filter.areMyStudies
      ? this.filterByCurrentUser(usersTab)
      : this.filterByUsers(usersTab, filter.users);
  }

  prepareFilterUsers(usersTab: UserInCampaign[], managerKey: string): UserInCampaign[] {
    let managerIC = new UserInCampaign();
    managerIC.key = managerKey;
    return _.uniqBy([managerIC].concat(usersTab), 'key');
  }

  filterListTraining(trainings: Training[] = [], filter: any): any[] {
    return trainings.filter((training: any) =>
      this.filterByText(training.name, filter.name) &&
      this.filterByState(training.state, filter.states) &&
      this.filterByFavorites(training, filter.displayFavoritesOnly)
    );
  }

  filterListWorkflow(workflows: Workflow[], filter: any, language: string = 'english'): any[] {
    return !workflows.filter ? workflows :
      workflows.filter(
        (workflow: Workflow) => workflow.name &&
          this.filterByText(workflow.name[language] || workflow.name.english, filter.name) &&
          this.filterByHubs(workflow.hub, filter.hubs) &&
          this.filterByState(workflow.state, filter.states) &&
          this.filterByFavorites(workflow, filter.displayFavoritesOnly) &&
          this.filterByTests(workflow, filter.displayTestsOnly)
      );
  }

  filterNameFromUser(users: UserInCampaign[]): any {
    return users.map((user: UserInCampaign) => _.omit(user, ['value', 'name']));
  }

  filterObjects(objects: (Block | Workflow)[] = [], displayFavorite: boolean = false, searchText: string = '', currentLanguage: string = 'english'): any[] {
    return objects.filter(
      (object: Block | Workflow) => {
        let name = object.name[currentLanguage] || object.name.english;
        let favorite = !displayFavorite || this.userService.isFavorite(object.id);
        return name.toLowerCase().includes(searchText.toLowerCase()) && favorite;
      }
    );
  }

  generateRandomID(size: number = 7): string {
    let data = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
    let randomId = '';
    for (let i = 0; i < size; i++) {
      randomId += data[this.getRandomInt(0, data.length)];
    }
    return randomId;
  }

  getApplicationSide(side: any): ApplicationSide {
    if (typeof side === 'number') {
      return side ? ApplicationSides.Left : ApplicationSides.Right;
    }
    return ApplicationSides.None;
  }

  getCoordinatesForPercent(percent) {
    const x = Math.cos(2 * Math.PI * percent);
    const y = Math.sin(2 * Math.PI * percent);

    return [x, y];
  }

  getDataURIImageInLocal(path): Observable<string> {
    return new Observable((illustration: Subscriber<string>) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', path, true);
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          illustration.next(e.target.result);
          illustration.complete();
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.send();
    });
  }

  getKeys(form) {
    let tab = [];
    _.forEach(form, function (element) {
      if (_.isArray(element) && element.length && element[0].key) {
        element.map(elementKey => {
          tab.push(elementKey.key);
        });
      } else if (element && element.key) {
        tab.push(element.key);
      }
    });
    return tab;
  }

  getListFormulasFromRoutine(campaign: Campaign | CampaignFormulas) {
    const listFormula = campaign.formula.routines.map((r: Routine2) => {
      const formulas = _.uniqBy(
        _.flattenDeep(
          r.visits.map((v: Visit) =>
            v.orders ? v.orders.map((o: Order) => // on vérifie si v.orders n'est pas undefined avant de map
              o.steps ? o.steps.map((s: RoutineStep) => { // on vérifie si o.steps n'est pas undefined avant de map
                return {
                  name: s.formula.formulaName
                };
              }) : []
            ) : []
          )
        ), 'id'
      );
      return new GroupedFormula(this.generateRandomID(), r.name, formulas);
    });
    const R2 = listFormula.splice(1, 1)[0];
    if (R2) {
      listFormula.unshift(R2);
    }
    return listFormula;
}


  /**
   * On renvoie un entier aléatoire entre une valeur min (incluse)
   * et une valeur max (exclue).
   * Attention : si on utilisait Math.round(), on aurait une distribution
   * non uniforme !
   * @param min
   * @param max
   */
  getRandomInt(min: number = 0, max: number = 10) {
    if (min > max) {
      [max, min] = [min, max];
    }
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  getSubObject(object: any, orderBy: string, isTranslatable: boolean = false): any {
    let currentlanguage = this.userService.getUser().language;
    let arraySortBy = orderBy.split('.');
    for (let data of arraySortBy) {
      object = object[data];
      if (object === undefined) {
        return '';
      }
    }
    //  Translatable property
    if (isTranslatable) {
      // If the currentLanguage is empty, return the english language
      object = object[currentlanguage] !== '' ? object[currentlanguage] : object.english;
    }
    return object;
  }

  getVolunteersForCampaign(arcsProject: Arcs): Volunteers[] {
    let panelists = _.get(arcsProject, 'panelists', []).map(p => {
      return {
        name: _.get(p, 'PanelistID', '').toString(),
        isActive: true
      };
    });
    return _.uniqBy(panelists, 'name');
  }

  getVolunteersForProject(users: ArcsParticipation[], system: HubById, studyArcs: string): Arcs {
    const panelistsStatusCode = [88, 90, 95, 98, 99];
    let filteredPanelists = _.filter(users, (p) => _.includes(panelistsStatusCode, p['StatusCode']));
    return {
      arcsSystem: system,
      arcsStudy: studyArcs,
      panelists: filteredPanelists
    };
  }

  hasFormulasAndUsers(campaign: Campaign | CampaignOnePager | CampaignParameters = new Campaign()) {
    return !_.isEmpty(_.get(campaign, 'users.accountables'), []) && !_.isEmpty(_.get(campaign, 'formula.listFormulas'), []);
  }

  indexingGraphs(graphs: Graph[]) {
    let indexes = {};
    for (let graph of graphs) {
      // if(!_.has(graph, 'id')) graph.id = this.generateRandomID();
      if (!indexes[graph.type.id]) {
        indexes[graph.type.id] = 0;
      }
      graph.type.index = indexes[graph.type.id]++;
      for (let i = 0; i < graph.descriptors.length; i++) {
        graph.descriptors[i].index = i;
      }
    }
    return graphs;
  }

  isDifferent<T>(currentObject: T, originalObject: T): boolean {
    return (originalObject && currentObject) ? !_.isEqual(originalObject, currentObject) : true;
  }

  onUpdateMenuTabs(): EventEmitter<boolean> {
    return this.updateMenu;
  }

  openModalConfirm(modalContent: ModalContent) {
    const modal = this.modalService.open(ConfirmModalComponent, {
      keyboard: false,
      backdrop: 'static',
      size: 'sm'
    });
    modal.componentInstance.title = modalContent.title;
    modal.componentInstance.message = modalContent.message;
    return observableFrom(modal.result);
  }

  public openCustomModal(modalContent: CustomModalContent): NgbModalRef {
    const modal = this.modalService.open(CustomModalComponent, {
      keyboard: false,
      backdrop: 'static',
      size: modalContent.size,
      windowClass: modalContent.windowClass
    });
    modal.componentInstance.title = modalContent.title;
    modal.componentInstance.message = modalContent.message;
    modal.componentInstance.customButtons = modalContent.customButtons;
    modal.componentInstance.bodyComponent = modalContent.bodyComponent;
    modal.componentInstance.bodyComponentInputs = modalContent.bodyComponentInputs;
    modal.componentInstance.bodyComponentOutputs = modalContent.bodyComponentOutputs;
    return modal;
  }

  pathProgress(progressEnd: number = 1, progressStart: number = 0) {
    let startX = this.getCoordinatesForPercent(progressStart)[0];
    let startY = this.getCoordinatesForPercent(progressStart)[1];
    let endX = this.getCoordinatesForPercent(progressEnd)[0];
    let endY = this.getCoordinatesForPercent(progressEnd)[1];

    const largeArcFlag = (progressEnd - progressStart) > .5 ? 1 : 0;

    if (isNaN(startX) || isNaN(startY) || isNaN(endX) || isNaN(endY)) {
      startX = startY = endX = endY = 0;
    }

    const pathData = [
      `M ${startX} ${startY}`,
      `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`,
      `L 0 0`,
    ].join(' ');

    return pathData;
  }

  removeWorkflowsInList(workflows: Workflow[], workflowsCampaign: Workflow[]): Workflow[] {
    return workflowsCampaign.reduce((arrayWorkflows, workflowInCampaign) => {
      _.remove(workflows, workflow => workflowInCampaign.id.includes(workflow.id));
      return workflows;
    }, workflows);
  }

  sortArrayOfKeyValueObjects(array: any[]): any[] {
    if (_.isArray(array)) {
      return array.sort((a, b) => {
        if (a && b) {
          return (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0);
        }
        return 1;
      });
    }
  }

  sortListByType(arrayObjects: any[], orderBy: any, reverse: boolean, isTranslatable: boolean = false, currentlanguage = 'english', fn?: Function): any[] {
    if (arrayObjects && arrayObjects.length !== 0) {
      arrayObjects.sort((a: any, b: any) => {
        let subA = fn ? fn(_.get(a, orderBy)) : _.get(a, orderBy);
        let subB = fn ? fn(_.get(b, orderBy)) : _.get(b, orderBy);
        if (isTranslatable) {
          subA = subA[currentlanguage] !== '' ? subA[currentlanguage] : subA.english;
          subB = subB[currentlanguage] !== '' ? subB[currentlanguage] : subB.english;
        }
        if (_.isNumber(subA) && _.isNumber(subB)) {
          return reverse ? subB - subA : subA - subB;
        }
        if (!subA) {
          return 1;
        } else if (!subB) {
          return -1;
        } else if (subA.toString().toLowerCase() < subB.toString().toLowerCase()) {
          return reverse ? 1 : -1;
        } else if (subB.toString().toLowerCase() < subA.toString().toLowerCase()) {
          return reverse ? -1 : 1;
        } else {
          return 0;
        }
      });
    }
    return arrayObjects;
  }

  sortTimeline = (a: Timeline, b: Timeline): number => {
    if (a && b) {
      if (a.date === b.date) {
        return 0;
      }
      return a.date > b.date ? -1 : 1;
    }
    return 0;
  };

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  translate(keys: string[]): Observable<string>[] {
    return keys.map(k => this.dnaTranslateService.translateMessage(k));
  }

  translateMessageModal(message: string, name: string, titleName: string): Observable<any> {
    return this.dnaTranslateService.translateMessage(titleName).pipe(
      mergeMap((translation: string) => {
        return forkJoin(
          this.dnaTranslateService.translateMessage('CONFIRMATION'),
          this.dnaTranslateService.translateMessage(message, { type: translation, name: name })
        ).pipe(
          map((success: [string, string]) => {
            return {
              title: success[0],
              message: success[1]
            };
          }));
      }));
  }

  updateMenuTabs() {
    this.updateMenu.emit();
  }

  getTab(tab: string) {
    switch (tab) {
      case 'head':
        return this.onePagerTabs.header;
      case 'context':
        return this.onePagerTabs.context;
      case 'target':
        return this.onePagerTabs.targetProtocol;
      case 'image':
        return this.onePagerTabs.images;
      case 'conclusion':
        return this.onePagerTabs.conclusions;
      case 'preview':
        return this.onePagerTabs.preview;
    }
  }

  dataUrlToBlob(data) {
    const byteString = atob(data.split(',')[1]);
    const mimeString = data.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  resizeImage(fileType: string, onLoadResult): Promise<string> {
    return new Promise((resolve: any, reject: any) => {
      let img = new Image();
      img.src = onLoadResult;
      let canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      let width, height, dataurl;
      img.onload = function () {
        var MAX_WIDTH = 1000;
        var MAX_HEIGHT = 1000;
        width = img.width;
        height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx2 = canvas.getContext('2d');
        ctx2.drawImage(img, 0, 0, width, height);
        dataurl = canvas.toDataURL(fileType);
        resolve(dataurl);
      };
    });
  }

  setUserWorkspace(user) {
    if (user) {
      this.workspaceService.getWorkspacesByUser(user.track).subscribe(
        (workspaces: Workspace[]) => {
          user.workspaces = workspaces;
          if (!user.currentWorkspace) {
            user.currentWorkspace = workspaces && workspaces.length > 0 ? workspaces[0] : new Workspace();
          }
          if (user.currentWorkspace.id) {
            user.currentWorkspace = workspaces.find(w => w.id === user.currentWorkspace.id);
          }
          this.userService.setUser(user, false);
        }
      );
    }
  }

  setUserLanguage(userPreferences: Preferences[]): string {
    const languagePref = _.get(userPreferences, '[0].language');
    if (languagePref) {
      return languagePref;
    } else {
      const language = window.navigator.language;
      switch (language.substr(0, 2)) {
        case 'fr':
          return 'french';
        case 'es':
          return 'spanish';
        case 'ja':
          return 'japanese';
        case 'pt':
          return 'portuguese';
        case 'zh':
          return 'chinese';
        default:
          return 'english';
      }
    }
  }

  setUserPreferences() {
    this.dnaTranslateService.setLanguage(this.setUserLanguage([]));
    const user = this.userService.getUser();
    if (user) {
      this.httpRestService.getUserPreferences(user).subscribe(
        preferences => {
          this.dnaTranslateService.setLanguage(this.setUserLanguage(preferences));
          this.dnaTranslateService.isLanguageChanges.next(true);
        });
    }
    this.setUserWorkspace(user);
  }

  formatTable(targetHead: string[], campaignTarget: Campaign[]) {
    if (!_.isEmpty(targetHead)) {
      setTimeout(() => {
        campaignTarget.forEach(ct => {
          let tdHeight = document.getElementById('td_' + ct.id + '_0').clientHeight;
          let tdHeightHead = document.getElementById('td_' + ct.id).clientHeight;
          if (tdHeight > tdHeightHead) {
            tdHeight = tdHeight + 2;
            document.getElementById('td_' + ct.id).style.height = tdHeight + 'px';
          } else {
            tdHeightHead = tdHeightHead + 1;
            for (let head = 0; head < targetHead.length; head++) {
              document.getElementById('td_' + ct.id + '_' + head).style.height = tdHeightHead + 'px';
            }
          }
        });
      });
    }
  }

  formatTargetData(campaignTarget: Campaign[] = []): Campaign[] {
    campaignTarget.forEach(cp => {
      if (_.isNil(cp.target)) {
        cp.target = [];
      }
    });
    return campaignTarget;
  }

  getTargetHead(campaignTarget): string[] {
    let head: string[] = [];
    campaignTarget.forEach(cp => {
      if (_.get(cp, 'target', undefined)) {
        for (let targetKey of Object.keys(cp.target)) {
          if (!_.isEmpty(cp.target[targetKey].filter(elm => elm.IsValue))) {
            head.push(targetKey);
          }
        }
      }
    });
    return _.uniq(head);
  }

  createDescriptor(campaignId: string, campaignName: string, workflowId: string, workflowName: Translatable, blockId: string, blockName: Translatable, blockSuffix: string, idInQuestionnaire: string, component: DNAComponent): Descriptor {
    const idInBlock: string = component.idInBlock;
    const name: Translatable = component.args.label;
    const descriptor: Descriptor = {
      campaignName: campaignName,
      campaignId: campaignId,
      blockName: blockName,
      blockSuffix: blockSuffix,
      idBlock: blockId,
      idInBlock: idInBlock,
      idInQuestionnaire: idInQuestionnaire,
      name: name,
      type: component.type,
      workflowName: workflowName,
      workflowId: workflowId,
      scale: this.getScale(component).scale,
      scaleDetail: this.getScale(component).detail,
      pertinance: 100
    };
    if (component.type === 'dna-confort') {
      descriptor.confortValues = _.get(component, 'args.confortValues', {});
    }
    return descriptor;
  }

  private getScale(component): { scale: string, detail: string } {
    switch (component.type) {
      case "dna-input-text":
        return {
          scale: 'Input Text',
          detail: 'Input Text'
        };
      case 'dna-multiple-choice':
        const detailMc = this.getScaleMultipleChoice(component);
        return {
          scale: detailMc.length < 15 ? detailMc : detailMc.slice(0, 15) + '...',
          detail: detailMc
        };
      case 'dna-counter-side':
        return {
          scale: this.getScaleCounterSide(component),
          detail: this.getScaleCounterSide(component)
        };
      case 'dna-confort':
        return {
          scale: 'Confort',
          detail: 'Confort'
        };
    }
  }

  private getScaleCounterSide(component: DNAComponent) {
    return this.dnaTranslateService.getTranslation(component.args.label, this.dnaTranslateService.getLanguage());
  }

  private getScaleMultipleChoice(component: DNAComponent): string {
    if (component.args.leftRightMode && (!_.isEmpty(_.get(component, 'args.valuesLeft')) && !_.isEmpty(_.get(component, 'args.valuesRight')))) {

      return this.getArrayTranslatation(component.args.valuesLeft, this.dnaTranslateService.getLanguage()).reduce(this.reduceString, '') + ' | ' + this.getArrayTranslatation(component.args.valuesRight, this.dnaTranslateService.getLanguage()).reduce(this.reduceString, '');
    } else {
      return this.getArrayTranslatation(component.args.values, this.dnaTranslateService.getLanguage()).reduce(this.reduceString, '');
    }
  }

  private getArrayTranslatation(array: Translatable, language: string): { value: string }[] {
    return array[language] && array[language].length > 0 ? array[language] : array.english;
  }

  private reduceString = (string: string, data: { value: string }, index: number) => {
    if (index > 0) {
      string += ' | ';
    }
    string += data.value;
    return string;
  };

  getName(descriptors: Descriptor[], key: string): string {
    const lang = this.dnaTranslateService.getLanguage();
    return descriptors.reduce((s, d, i) => {
      if (i > 0) {
        s += ' | <br>';
      }
      s += this.dnaTranslateService.getTranslation(d[key], lang);
      return s;
    }, '');
  }

  getNameGroup(descriptorsGroup: DescriptorGroup[], key: string): string[] {
    let liste = [];
    descriptorsGroup.forEach(dg => {
      liste.push(this.getName(dg.descriptors, key));
    });
    return _.uniq(liste);
  }

  setAllCountries(language) {
    const allCountriesArray: any[] = [];
    let countriesObject: any = {};
    switch (language) {
      case 'english':
        countriesObject = i18nIsoCountries.getNames('en', { select: 'official' });
        break;
      case 'french':
        countriesObject = i18nIsoCountries.getNames('fr', { select: 'official' });
        break;
      case 'japanese':
        countriesObject = i18nIsoCountries.getNames('ja', { select: 'official' });
        break;
      case 'chinese':
        countriesObject = i18nIsoCountries.getNames('zh', { select: 'official' });
        break;
      case 'portuguese':
        countriesObject = i18nIsoCountries.getNames('pt', { select: 'official' });
        break;
      case 'spanish':
        countriesObject = i18nIsoCountries.getNames('es', { select: 'official' });
        break;
      default:
        countriesObject = i18nIsoCountries.getNames('en', { select: 'official' });
        break;
    }
    _.forOwn(countriesObject, function (value, key) {
      value = _.replace(value, value.substring(0, 1), _.deburr(value.substring(0, 1)));
      allCountriesArray.push({ id: key, value: value });
    });
    return _.orderBy(this.filterCountries(allCountriesArray), 'value', 'asc');
  }

  filterCountries(allCountries) {
    // let keys = ["AF", "ZA", "AL", "DZ", "DE", "SA", "AR", "AU", "AT", "BR", "BG", "CA", "CN", "KR", "KP", "ES", "US", "RU", "FR", "IN",
    // "ID", "IT", "JP", "KE", "MK", "MA", "MU", "MX", "NG", "PL", "PT", "RO", "GB", "SG", "TH", "TN", "UA"];

    let keys = ['ZA', 'DE', 'AR', 'AU', 'BR', 'CA', 'CN', 'KR', 'ES', 'US', 'RU', 'FR', 'IN',
      'ID', 'IT', 'JP', 'MX', 'PT', 'GB', 'SG', 'TH'];

    let filteredCountries = allCountries.filter(c => _.includes(keys, c.id));
    return filteredCountries;
  }

  uploadFile(file: any) {
    if (this.isImageFile(file) && file.size > 5000000) {
      this.errorManager.displayMessage('TOO_LARGE_FILE', 'danger');
      return observableOf(undefined);
    }
    return new Observable((fileLoad: Subscriber<[any, string]>) => {
      let array: string, index: number = 0, reader: FileReader;
      reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        array = e.target.result.split(',');
        fileLoad.next([file, array]);
      };
    });
  }

  isImageFile(file: File): boolean {
    return (file.type.search(/^image\//i) === 0);
  }


  uploadMediaFromComputer(file, media: string, type: string): Observable<string> {
    const newUuid = uuid();
    if (file && this.isImageFile(file) && file.size > 5000000) {
      this.errorManager.displayMessage('TOO_LARGE_FILE', 'danger');
      return observableOf(undefined);
    }
    return this.httpRestService.uploadMedia(new ImageBlob(media, type), newUuid).pipe(
      map((response: { mediaUUID: string }) => `${environment.apiStudiesUrl}/v1/medialoader/${response.mediaUUID}`));
  }

  setImage(url: string, name: Translatable, data: string, id: string, indexImage: number): ImageFromDNA {
    const nameCapture = name
      ? this.dnaTranslateService.getTranslation(name, this.dnaTranslateService.getLanguage())
      : `capture-${new Date().getTime()}`;
    const image: ImageFromDNA = {
      name: nameCapture,
      url: url,
      id: id,
      indexImage: indexImage
    };
    if (data) {
      image.data = observableOf(data);
    }
    return image;
  }

  canActivate(campaign: CampaignOnePager | CampaignParameters, router: Router, errorManager: ErrorManagerService): boolean {
    const hasFormulasAndUsers = this.hasFormulasAndUsers(campaign);
    if (!hasFormulasAndUsers) {
      errorManager.displayMessage('ERROR_INCOMPLETE_CAMPAIGN', 'danger');
      router.navigate(_.isEmpty(_.get(campaign, 'users.accountables'))
        ? ['campaigns', campaign.id, 'edit', 'users']
        : ['campaigns', campaign.id, 'edit', 'formulas']);
    }
    return hasFormulasAndUsers;
  }

  scrollToBottom() {
    setTimeout(() => {
      document.documentElement.scrollTo(0, document.documentElement.scrollHeight);
    });
  }

  isNotEditable(state: string) {
    return state !== CampaignStates.Draft && state !== CampaignStates.Suspended;
  }

  saveRedirectURL(url) {
    if (url) {
      this.localStorageService.setItem("DNA_Url", url);
    }
  }

  getUrl() {
    return this.localStorageService.getItem('DNA_Url');
  }

  removeUrl() {
    return this.localStorageService.remove('DNA_Url');
  }

  languageToISOCode(language: Language) {
    switch (language) {
      case 'english':
        return 'EN';
      case 'french':
        return 'FR';
      case 'japanese':
        return 'JA';
      case 'chinese':
        return 'ZH';
      case 'portuguese':
        return 'PT';
      case 'spanish':
        return 'ES';
      default:
        return 'EN';
    }
  }

  addSuffix(blockName: Translatable, blockSuffix: string) {
    _.forOwn(blockName, (value, key) => {
      if (!_.isEmpty(value)) {
        blockName[key] = value.concat(blockSuffix);
      }
      ;
    });
    return blockName;
  }

  getHubFromArcs(hub: HubById) {
    switch (hub) {
      case 'BR':
        return 'Brazil';
      case 'CH':
        return 'China';
      case 'IN':
        return 'India';
      case 'JP':
        return 'Japan';
      case 'US':
        return 'US';
      case 'ZA':
        return 'SouthAfrica';
      case 'ME':
        return 'Mexico';
      default:
        return 'France';
    }
  }

  getAttributesDataByHub(hub: HubById) {
    switch (hub) {
      case HubsById.France:
        return this.httpRestService.getPanelistInformation(HubsById.France, '272170');
      case HubsById.US:
        return this.httpRestService.getPanelistInformation(HubsById.US, '155420');
      case HubsById.Japan:
        return this.httpRestService.getPanelistInformation(HubsById.Japan, '4343');
      case HubsById.Brazil:
        return this.httpRestService.getPanelistInformation(HubsById.Brazil, '11047');
      case HubsById.India:
        return this.getAttributeFromNonArcs(HubsById.India, '0091');
      case HubsById.Mexico:
        return this.getAttributeFromNonArcs(HubsById.Mexico, '0052');
      case HubsById.SouthAfrica:
        return this.getAttributeFromNonArcs(HubsById.SouthAfrica, '0027');
      case HubsById.China:
        return this.getAttributeFromNonArcs(HubsById.China, '0086');
      case HubsById.ChinaISO:
        return this.getAttributeFromNonArcs(HubsById.ChinaISO, '8686');
      default:
        return this.httpRestService.getPanelistInformation(HubsById.France, '272170');
    }
  }

  getAttributeFromNonArcs(hub: HubById, panelistNumber?: string) {
    return this.httpRestService.getPanelistHorsArcsInformation(hub, panelistNumber).pipe(
      catchError(err => {
        if (err.error && Array.isArray(err.error.classifications) && err.error.classifications.length > 0) {
          return of({classifications : err.error.classifications});
        }
        throw new Error(err.error.errors.join(','));
      })
    );
  }

  getAttributesData(result: ArcsPanelist, metier: Metier): AttributeData[] {
    let classification;
    switch (_.get(metier, 'name', '')) {
      case Metiers.Hair: {
        classification = _.get(result, 'classifications', []).find(c => c.Name === 'DNA API HAIR' || c.Name === 'DNA API Hair');
        break;
      }
      case Metiers.Skin: {
        classification = _.get(result, 'classifications', []).find(c => c.Name === 'DNA API SKIN' || c.Name === 'DNA API Skin');
        break;
      }
      default: {
        classification = _.get(result, 'classifications', []).find(c => c.Name === 'DNA API');
        break;
      }
    }
    if (!classification) {
      classification = _.get(result, 'classifications', []).find(c => c.Name === "DNA API");
    }
    return _.get(classification, 'AttributeData', []).map((a: AttributeData) => _.pick(a, ['AttributeID', 'AttributeType', 'AttributeName']));
  }

  onTabParametersChanged(type: string, links: __Links): Observable<Resp> {
    let params = '';
    switch (type) {
      case 'nextPage':
        params = links.next.href;
        break;
      case 'previousPage':
        params = links.previous.href;
        break;
      case 'lastPage':
        params = links.last.href;
        break;
      case 'firstPage':
        params = links.first.href;
        break;
    }
    return this.httpRestService.executeTableRequest(params);
  }

  getParams(filters: any, isMulti?: boolean) {
    let params = '', paramFilters = '';
    Object.keys(filters).forEach(key => {
      if (filters[key] && (filters[key].length || filters[key] > 0 || Object.keys(filters[key]).length)) {
        switch (key) {
          case 'page':
          case 'limit':
          case 'orderBy':
          case 'order':
            params = params === '' ? params.concat(`${key}=${filters[key]}`) : params.concat('&', `${key}=${filters[key]}`);
            break;
          case 'displayFavoritesOnly':
            paramFilters = paramFilters.concat(`id[in]=(${this.userService.getUser().favorites
                .filter(favorite => favorite.type === 'campaign')
                .map(favorite => `'${favorite.id}'`)
                .join(',')});`);
            break;
          case 'displayTemplate':
            paramFilters = paramFilters.concat(`parameters.isTemplate[eq]=true;`);
            break;
          case 'studyType':
            paramFilters = paramFilters.concat(`studyType.id[in]=(${filters[key].map(s => `'${s.id}'`).join(',')});`);
            break;
          case 'typeEvaluation':
            paramFilters = paramFilters.concat(`evaluationType.id[in]=(${filters[key].map(s => `'${s.id}'`).join(',')});`);
            break;
          case 'actiview':
            if (isMulti) {
              paramFilters = paramFilters.concat(`activityNumber[ct]=${filters[key][0].value};`)
            } else {paramFilters = paramFilters.concat(`actiview.activityNumber[ct]=${filters[key][0].value};`)};
            break;
          case 'bridge':
            if (isMulti) {
              paramFilters = paramFilters.concat(`requestNumber[ct]=${filters[key][0].value};`)
            } else {paramFilters = paramFilters.concat(`synergy.requestNumber[ct]=${filters[key].map(s => `${s.value}`).join(',')};`)};
            break;
          case 'orchestra':
            paramFilters = paramFilters.concat(`synergy.projectNumber[in]=(${filters[key].map(s => `'${s.value}'`).join(',')});`);
            break;
          case 'byUser':
            paramFilters = paramFilters.concat(`users.accountables[ct]=${this.userService.getUser().track};`);
            break;
          case 'hubs':
            if (isMulti) {
              filters[key].forEach(h => {
                paramFilters += `hub[eq]=${h};`;
              });
            } else {
              paramFilters = paramFilters.concat(`hub[in]=(${this.getFilterStates(filters, key)});`);
            }
            break;
          case 'states':
            paramFilters = paramFilters.concat(`state[in]=(${this.getFilterStates(filters, key)});`);
            break;
          case 'users':
            const filteredUsers = filters[key].map(user => user.key);
            paramFilters = paramFilters.concat(`users.accountables[ct]=${filteredUsers};`);
            break;
          case 'workflows':
            const filteredWorkflows = filters[key].map(workflows => workflows.name);
            if (isMulti) {
              paramFilters = paramFilters.concat(`workflowsMulti[ct]=${encodeURIComponent(filteredWorkflows.join('~'))};`);
            } else {
            paramFilters = paramFilters.concat(`workflows[ct]=${encodeURIComponent(filteredWorkflows.join(','))};`);}
            break;
          case 'formulas':
            const filteredFormulas = filters[key].map(formulas => formulas.name);
            if (isMulti) {
              paramFilters = paramFilters.concat(`formulasMulti[ct]=${filteredFormulas.join(',')};`);
            } else {
              paramFilters = paramFilters.concat(`formulas[ct]=${filteredFormulas};`);}
            break;
          case 'hub':

            break;
          case 'target':
            //TODO(voir v1)
            break;
          case 'startDate':
            paramFilters = paramFilters.concat(`updated_on[gt]=${filters[key].getTime()};`);
            break;
          case 'endDate':
            paramFilters = paramFilters.concat(`updated_on[lt]=${new Date(filters[key]).getTime()};`);
            break;
          case 'timepointsNb':
            paramFilters = paramFilters.concat(`protocol.timepoints[eq]=${filters[key]};`);
            break;
          case 'protocol':
            Object.keys(filters[key]).forEach((field)=>{
              let protocolKey = field
              if(filters[key][field] && _.isArray(filters[key][field]) && filters[key][field].length > 0){
                if(field === 'applicationTypes' || field === 'substrates' || field === 'tests' || field === 'studyTypes' || field === 'methods'){
                  protocolKey = field.substring(0, field.length - 1) //Ces champs ont un 's' dans l'objet Filter mais sont stockées avec la clé au singulier dans la base de donnée d'ou l'artifice pour supprimer la 's'
                }
                paramFilters = paramFilters.concat(`protocol.${protocolKey}[${this.getOperatorByProtocolKey(protocolKey)}]=${filters[key][field].map((protocol)=>protocol.key)};`);
                return paramFilters
              }
            })
            break;
          default:
            paramFilters = Array.isArray(filters[key])
              ? paramFilters.concat(`${key}[in]=(${filters[key].map(s => `'${s}'`).join(',')});`)
              : paramFilters.concat(`${key}[ct]=${filters[key]};`);
            break;
        }
      }
    })

    return params.concat(`&filters=[${paramFilters}]`);
  }

  getFilterStates(filters, key) {
    return filters[key].map(s => `'${s}'`).join(',');
  }
  getOperatorByProtocolKey(protocol:string){
    const protocolsType = {
      'studyType':'ct',
      'test':'ct',
      'substrate':'ct',
      'applicationType':'ct',
      'applicationOrder':'ct',
      'applicationAreas': 'arr_ct',
      'scales': 'arr_ct',
      'studies': 'arr_ct',
      'protocol_methods': 'arr_ct',
      'method':'arr_ct',
    }[protocol]
    return protocolsType
  }
  uploadMediaPhotosFromDevice(file, media: string, type: string, mediaUUID:string = undefined): Observable<string> {
    if(!mediaUUID){
      mediaUUID = uuid();
    }
    console.log("mediaUUID upload:", mediaUUID)
    if (file && this.isImageFile(file) && file.size > 5000000) {
      this.errorManager.displayMessage('TOO_LARGE_FILE', 'danger');
      return observableOf(undefined);
    }
    return this.httpRestService.uploadMediaPhotos(new ImageBlob(media, type), mediaUUID).pipe(
      map((response: { mediaUUID: string }) => `${environment.apiStudiesUrl}/v1/medialoaderdnaphotos/${response.mediaUUID}`));
  }
}
