import {
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import {
  Panelist,
  PanelistTimeline,
  TimelineTypes,
  TimelineTypesDB
} from '@app/types';
import {
  Router, ParamMap, ActivatedRoute
} from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnInit, ViewEncapsulation
} from '@angular/core';
import {
  VolunteerService
} from '../../volunteers.service';
import * as _ from 'lodash';
import { map, tap, catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { ErrorManagerService } from '../../../shared/services/errorManager.service';
import { NgxTimelineEvent, NgxTimelineEventChangeSideInGroup, NgxTimelineEventGroup } from '@frxjs/ngx-timeline';
import { NgxTimelineItem } from '@frxjs/ngx-timeline/lib/models/NgxTimelineEvent';
import { ApplicationInsightsService } from '@app/shared/services/applicationInsights.service';
const LAST_TIME_DATE_MS = (23 * 60 * 60 * 1000) + (60 * 60 * 1000 - 1); // 23:59:59:999

@Component({
  selector: 'dna-general-information-volunteer',
  templateUrl: 'generalInformation.component.html',
  styleUrls: ['./generalInformation.less'],
  encapsulation: ViewEncapsulation.None
})

export class GeneralInformationVolunteerComponent implements OnInit, AfterViewInit {
  panelist: Panelist;
  timelines: PanelistTimeline[];
  timelineEvents: NgxTimelineEvent[];
  dateStart: NgbDateStruct;
  dateEnd: NgbDateStruct;
  isCollapsedFilter: boolean = true;
  bridge: string;
  showSpinner: boolean = false;
  initTime = performance.now();

  dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  constructor(
    private router: Router,
    private volunteerService: VolunteerService,
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute,
    private errorManager: ErrorManagerService
  ) {
  }

  ngOnInit() {
    this.panelist = this.volunteerService.getPanelist();
    this.initTimeline();
    this.formatTimelineEvents();
    this.initDates();
    this.route.paramMap.pipe(
      map((params: ParamMap) => params.get('bridgeNumber')),
      tap((bridge: string) => this.bridge = bridge)
    ).subscribe();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot && this.route.snapshot.routeConfig ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Volunteer Details General Information', '', performance.now() - this.initTime, templateUrl);
  }

  initDates() {
    let dateS = new Date();
    this.dateStart = {
      year: dateS.getFullYear() - 1,
      month: dateS.getMonth() + 1,
      day: dateS.getDate()
    };
    this.dateEnd = {
      year: dateS.getFullYear(),
      month: dateS.getMonth() + 1,
      day: dateS.getDate()
    };
  }

  formatTimelineEvents() {
    this.timelineEvents = this.timelines.map(timeline => {
      return {
        id: timeline.id,
        timestamp: new Date(timeline.created_on),
        title: this.volunteerService.getTradFiche(timeline.subclass),
        description: timeline.text
      };
    });
  }

  getYear(period) {
    console.log(period);
    return "";
  }

  initTimeline() {
    // this.panelist.timeline.sort(this.utilService.sortTimeline);
    this.timelines = _.cloneDeep(this.panelist.timeline);
    this.timelines = this.panelist.timeline.filter(t => {
      let date = new Date();
      date.setFullYear(new Date().getFullYear() - 1);
      return t.created_on > date.getTime();
    });
    this.timelines = this.timelines.sort((a, b) => {
      if (a.created_on === b.created_on) {
        return 0;
      }
      return a.created_on < b.created_on ? - 1 : 1;
    });
  }

  onDateChanged() {
    //on convertit la date reçue en UTC et on la set à 00:00:00 pour prendre en compte la journée complète
    let dStart = new Date(Date.UTC(this.dateStart.year, this.dateStart.month - 1, this.dateStart.day));
    const dStartFirstTime = new Date(dStart.getTime() - dStart.getTimezoneOffset() * 60 * 1000).getTime();
    //on convertit la date reçue en UTC et on la set à 23:59:59:999 pour prendre en compte la journée complète
    let dEnd = new Date(Date.UTC(this.dateEnd.year, this.dateEnd.month - 1, this.dateEnd.day));
    const dEndLastTime = new Date(dEnd.getTime() - dEnd.getTimezoneOffset() * 60 * 1000 + LAST_TIME_DATE_MS).getTime();
    this.timelines = this.panelist.timeline.filter(t => {
      return t.created_on > dStartFirstTime && t.created_on < dEndLastTime;
    });
    this.formatTimelineEvents();
  }

  goToTimelineElement(timelineItem: NgxTimelineItem) {
    const timeline = this.timelines.find(currentTimeline => currentTimeline.id === timelineItem.eventInfo.id);

    switch (timeline.subclass) {
      case TimelineTypesDB.FICHE_CARACTERISATION: {
        this.goToFicheCaracterisation(timeline.id);
        break;
      }
      case TimelineTypesDB.FICHE_REMANENCE: {
        this.goToFicheRemanence(timeline.id);
        break;
      }
      case TimelineTypesDB.FICHE_ENTRETIEN: {
        this.goToFicheEntretien(timeline.id);
        break;
      }
      case TimelineTypesDB.STUDY: {
        this.goToStudy(timeline.studyId);
        break;
      }
      case TimelineTypesDB.VISIT: {
        this.goToStudy(timeline.studyId);
        break;
      }
    }
  }

  goToFicheCaracterisation(id = 'last') {
    let path = ['volunteers', this.panelist.panelistNumber, this.panelist.hub, 'ficheCaracterisation', id];
    if (this.bridge) {
      path = path.concat(['bridge', this.bridge]);
    }
    this.router.navigate(path);
  }

  goToFicheRemanence(id = 'new') {
    let path = ['volunteers', this.panelist.panelistNumber, this.panelist.hub, 'ficheRemanence', id];
    if (this.isEditable(TimelineTypes.FICHE_REMANENCE, id)) {
      path.push('edit');
    }
    if (this.bridge) {
      path = path.concat(['bridge', this.bridge]);
    }
    this.router.navigate(path);
  }

  goToFicheEntretien(id = 'new') {
    let path = ['volunteers', this.panelist.panelistNumber, this.panelist.hub, 'ficheEntretien', id];
    if (this.isEditable(TimelineTypes.FICHE_ENTRETIEN, id)) {
      path.push('edit');
    }
    if (this.bridge) {
      path = path.concat(['bridge', this.bridge]);
    }
    this.router.navigate(path);
  }

  goToStudy(id) {
    this.router.navigate(['campaigns', id, 'edit', 'rawdata']);
  }

  private isEditable(type: TimelineTypes, id: string): boolean {
    let filteredTimeline = this.panelist.timeline.filter(tl => {
      return tl.subclass === type;
    });
    return filteredTimeline[0]
      ? filteredTimeline[0].id === id
      : false;
  }

  refreshTimeline() {
    this.showSpinner = true;
    this.volunteerService.getPanelistUpdatedTimeline(this.panelist.id).pipe(
      catchError(error => {
        this.errorManager.displayMessage('ON_ERROR_UPDATE');
        return of();
      }),
      tap((panelist: Panelist) => {
        this.panelist = panelist;
        this.initTimeline();
        this.showSpinner = false;
      }),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  protected readonly NgxTimelineEventGroup = NgxTimelineEventGroup;
  protected readonly NgxTimelineEventChangeSideInGroup = NgxTimelineEventChangeSideInGroup;
}
