import {
  DNAComponent,
  AnswersLeftRight,
  DescriptorKeyTypes,
  DESCRIPTOR_KEY,
  Translatable,
  DNAKey,
  IndexOperator
} from '@app/types';
import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter
} from '@angular/core';
import * as _ from 'lodash';
import { ReferenceTypeService } from '@app/shared/services/reference-type.service';

@Component({
  selector: 'dna-multiple-choice-config',
  templateUrl: './multipleChoiceConfiguration.component.html',
  styleUrls: ['./multipleChoiceConfiguration.css']
})

export class MultipleChoiceConfigurationComponent implements OnInit {
  @Input() inactive: boolean;
  @Input() submitted: boolean;
  @Input() component: DNAComponent;
  @Output() updateError: EventEmitter<boolean> = new EventEmitter();
  AnswersLeftRight = AnswersLeftRight;
  descriptorKeyTypes: any[];
  tabPreviewValues: any[];
  tabPreviewKeys: DNAKey[];
  indexOperators: string[];

  constructor(
    private referenceTypeService: ReferenceTypeService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.descriptorKeyTypes = DescriptorKeyTypes;
    if (this.component.args.leftRightMode) {
      this.buildTabPreview();
    } else if (_.isEmpty(this.component.args.keys)) {
      this.updateKeys();
    }
    this.sendError();
    this.indexOperators = this.referenceTypeService.getIndexOperators();
    if (!this.component.args.operator || this.component.args.operator == '+' || this.component.args.operator == '-') {
      this.component.args.operator = IndexOperator.INDEX;
    }
    if (_.get(this.component, 'args.operator') === 'EVALUATION_SIDE') {
      this.component.args.operator = IndexOperator.ONE_BASELINE;
    }
    if (!_.get(this.component, 'args.descriptorKeyType', undefined)) {
      _.set(this.component, 'args.descriptorKeyType', DESCRIPTOR_KEY.DEFAULT);
    }
  }

  updateValues() {
    _.get(this.component, 'args.leftRightMode', false) ? this.buildTabPreview() : this.updateKeys();
  }

  compareDescriptor(option1: any, option2: any): boolean {
    return option2 ? option1.id === option2.id : false;
  }

  private updateKeys() {
    this.component.args.keys = [];
    if (!_.isEmpty(this.component.args.values.english)) {
      this.component.args.values.english.map((v, i) => {
        _.get(this.component, 'args.keys', []).push({id: i});
      });
    }
  }

  updateLeftRightMode() {
    this.component.args.leftRightMode = !_.get(this.component, 'args.leftRightMode', false);
    if (this.component.args.leftRightMode) {
      this.component.args.keys = [];
      _.forEach(this.component.args.values, (value, key) => this.component.args.values[key] = []);
    } else {
      // si le mode gauche-droite est désactivé, on vide les clés en mémoire pour les mettre dans celles propres à un questionnaire sans côté
      // puis on désactive le mode central si activé car il n'a plus de raison d'être
      this.component.args.keys = _.cloneDeep(this.component.args.keysLeft);
      this.component.args.keysLeft = [];
      this.component.args.keysRight = [];
      _.forEach(this.component.args.valuesLeft, (value, key) => this.component.args.valuesLeft[key] = []);
      _.forEach(this.component.args.valuesRight, (value, key) => this.component.args.valuesRight[key] = []);
      _.forEach(this.component.args.centralValue, (value, key) => this.component.args.centralValue[key] = []);

      if (this.component.args.hasCentralValue) { this.toggleCentralValue(); }
    }
  }

  sendError() {
    const hasValues = !_.isEmpty(_.get(this.component, 'args.values.english', []));
    const leftRightMode = _.get(this.component, 'args.leftRightMode');
    const descriptor = _.has(this.component, 'args.descriptorKeyType');
    const centralValue = _.get(this.component, 'args.centralValue.english', '');
    const hasCentralValue = _.get(this.component, 'args.hasCentralValue', false);
    const indexesPositiveNumbers = _.get(this.component, 'args.keys', []).every(key => _.isNumber(key.id));
    const indexesUniq = _.get(this.component, 'args.keys', []).length === _.uniqBy(_.get(this.component, 'args.keys', []), 'id').length;

    const hasValue = !leftRightMode && hasValues && indexesPositiveNumbers && indexesUniq;
    const hasLeftRightValue = leftRightMode && hasValues && descriptor && ((hasCentralValue && centralValue !== '') || !hasCentralValue);
    const hasError = !(hasLeftRightValue || hasValue);
    this.updateError.emit(hasError);
  }

  toggleCentralValue() {
    this.component.args.hasCentralValue = !this.component.args.hasCentralValue;
    this.component.args.descriptorKeyType = _.get(this.component, 'args.hasCentralValue')
      ? DESCRIPTOR_KEY.FOUR
      : DESCRIPTOR_KEY.DEFAULT;
    this.component.args.centralValue = new Translatable();
    this.component.args.centralValue.english = [{value: ''}];
    this.buildTabPreview();
  }

  buildTabPreview() {
    this.tabPreviewValues = [];
    this.tabPreviewKeys = [];
    const valuesAsc = _.cloneDeep(_.get(this.component, 'args.values.english', []));
    const valuesDesc = _.reverse(_.cloneDeep(valuesAsc));
    const centralValue = _.get(this.component, 'args.centralValue.english[0].value', '');
    this.component.args.valuesLeft.english = valuesAsc;
    this.component.args.keysLeft = [];
    this.component.args.keysRight = [];
    const tabKeysAsc = [];
    const tabKeysDesc = [];

    if (valuesAsc) {
      valuesAsc.forEach((vl, index) => {
        tabKeysAsc.push({id: index + 1});
        tabKeysDesc.push({id: (valuesAsc.length) - index});
      });

      switch (_.get(this.component, 'args.descriptorKeyType.id')) {
        case '1': // 'a b c - a b c'
          this.component.args.keysLeft = this.component.args.keysRight = tabKeysAsc;
          this.tabPreviewValues = _.concat(valuesAsc, valuesAsc);
          this.component.args.valuesRight.english = valuesAsc;
          break;
        case '2': // 'c b a - c b a'
          this.component.args.keysLeft = this.component.args.keysRight = tabKeysDesc;
          this.tabPreviewValues = _.concat(valuesAsc, valuesAsc);
          this.component.args.valuesRight.english = valuesAsc;
          break;
        case '3': // 'a b c - c b a'
          this.component.args.keysLeft = tabKeysAsc;
          this.component.args.keysRight = tabKeysDesc;
          this.tabPreviewValues = _.concat(valuesAsc, valuesDesc);
          this.component.args.valuesRight.english = valuesDesc;
          break;
        case '4': // 'c b a - a b c'
          this.component.args.keysLeft = tabKeysDesc;
          this.component.args.keysRight = tabKeysAsc;
          this.tabPreviewValues = _.concat(valuesAsc, valuesDesc);
          this.component.args.valuesRight.english = valuesDesc;
          break;
        case '5': // 'c b a = a b c'
          this.component.args.centralKey = {id: '0'};
          this.component.args.keysLeft = tabKeysDesc;
          this.component.args.keysRight = tabKeysAsc;
          this.tabPreviewValues = _.concat(valuesAsc, {value:  centralValue}, valuesDesc);
          this.component.args.valuesRight.english = valuesDesc;
          break;
      }
      this.tabPreviewKeys = _.get(this.component, 'args.hasCentralValue')
        ? _.concat(this.component.args.keysLeft, this.component.args.centralKey, this.component.args.keysRight)
        : _.concat(this.component.args.keysLeft, this.component.args.keysRight);
    }
  }

  isInvalidIndex(index) {
    return this.component.args.keys.filter(k => k.id === index).length > 1;
  }
}
