import {
  Injectable
} from '@angular/core';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UserService } from './user.service';

@Injectable()
export class ApplicationInsightsService {

  private appInsight: ApplicationInsights;

  constructor(private router: Router, private userService: UserService) {
    this.appInsight = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey(),
        enableAutoRouteTracking: false,
        overridePageViewDuration: true,
      }
    });
    this.appInsight.addTelemetryInitializer(this.telemetryInitializer);
    this.appInsight.loadAppInsights();
  }

  telemetryInitializer (envelope) {
    envelope.tags['ai.cloud.role'] = 'DNA WebApp';
    envelope.tags['ai.cloud.roleInstance'] = 'WebApp instance';
    envelope.data = {
      clientApp: 'DNA WebApp'
    };
  }

  trackException (exception) {
    this.appInsight.trackException(exception);
  }

  logPageView(name?: string, properties?: any, duration?: number, templateUrl?: string) {
    if (this.userService.getUser()) {
      this.appInsight.trackPageView({
        name,
        uri: this.router.url,
        properties: {
          ...properties,
          duration: duration,
          rdUser: this.userService.getPeopleUserKey(),
          userId: this.userService.getUser().track,
          hub: this.userService.getUser().hub,
          templateUrl
        }
      });
    }
  }
}
