<div class="dna-chart-tile" #tile>
    <div class="dna-chart-tile--padding-top"></div>
    <dna-graph-options [notDisplayable]="!this.element?.chart?.tableRawData?.thead?.length"
        [identifier]="this.element.identifier" [parameters]="this.options" (handleDownload)="this.export()"
        (handleExcelDownload)="this.exportAsExcel()" (saveGraphOptions)="saveGraphOptions($event)" [initialComment]="this.element.comment">
    </dna-graph-options>

    <div #templateToExport class="areaCapture">
        <div *ngFor="let routine of this.routines" [routine]="routine" dna-routines-container>
        </div>
        <div dna-table-raw-data
            *ngIf=" this.element?.chart?.tableRawData?.thead?.length && this.element?.chart?.parameters?.details"
            [data]=" this.element.chart.parameters.details "
            [lang]=" this.lang "
            [title]=" 'VIZUALIZATIONS.TORNADO.TABLE_DATA'"
            [style.width.%]="'100'"
            [style.margin-bottom.em]="'2'">
        </div>
        <ng-template #tableGraph></ng-template>
        <pre class="comment-text" *ngIf="comment !== ''" [style.fontSize]="commentFontSize + 'px'" [style.fontWeight]="commentFontBold ? 'bold' : 'normal'">{{ comment }}</pre>
    </div>
    <div #rawData dna-table-raw-data *ngIf="this.element?.chart?.tableRawData?.thead?.length && this.rawDataVisible"
        [data]="this.element?.chart?.tableRawData" [lang]="this.lang"></div>
    <div class="dna-graph--actions" *ngIf="this.element?.chart?.tableRawData">
        <button [attr.disabled]="!this.element?.chart?.tableRawData?.thead?.length ? '' : null" class="button--link"(click)="this.handleRawDataVisibility()"
            dna-button [translate]="this.rawDataVisible ? 'VIZUALIZATIONS.HIDE_RAW_DATA' : 'VIZUALIZATIONS.SHOW_RAW_DATA'"></button>
    </div>
</div>
